// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDH3eFolE9HUmNDbw3qN9GYrhjEhHiw0Po",
    authDomain: "spscloud-e9e6a.firebaseapp.com",
    databaseURL: "https://spscloud-e9e6a.firebaseio.com",
    projectId: "spscloud-e9e6a",
    storageBucket: "spscloud-e9e6a.appspot.com",
    messagingSenderId: "955845920778",
    appId: "1:955845920778:web:6ca737a7eb51a1cdda77f3",
    measurementId: "G-E20F4P1T1C"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
