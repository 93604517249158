<div class="empty-block">
    <div class="center-block-empty">
        <div class="empty-img">
            <img src="https://mobilecenter.azureedge.net/generated/img/no-apps-db6363dd644196e2291b243bc210e1b0.svg"
                alt="empty">
        </div>
        <div class="empty-text">
            <p class="empty-state-title">
                {{name}}
            </p>
            <p class="empty-state-desc">
                {{subtitle}}
            </p>
            <button mat-button *ngIf="buttonText" class="add-button" (click)="create()">{{buttonText}}</button>
        </div>
    </div>
</div>