import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_FUNCTIONS, APP_NAME_LIST } from 'src/app/core/dummy/config';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { Environment } from 'src/app/core/stores/environment';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  APP_NAME: any = null;
  APP_OPTIONS: any[] = [];

  constructor(public store: AuthStore
    , public router: Router
    , public evn: Environment) { }

  async ngOnInit() {
    const urls = `${this.router.url}`.split('/');
    this.APP_NAME = APP_NAME_LIST.find(m => m.key === (urls.length > 0 && urls[1])) || null;
    this.APP_OPTIONS = (this.APP_NAME && APP_FUNCTIONS[this.APP_NAME.key]) || null;
    // await this.evn.fetchTermEnvironment()
  }

}