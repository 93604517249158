import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findAnswer'
})
export class FindAnswerPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (Array.isArray(value)) {
      return value[0]
    }
    return value;
  }
}
