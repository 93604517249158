<ng-container *mobxAutorun>
    <div class="bootstrap-mock-springboard-view" aria-hidden="true"
        style="position: fixed;overflow: hidden; pointer-events: none; user-select: none; width: 100%; height: 610px; filter: blur(60.5667px); left: 0px; top: 66px;">
        <div
            style="position: absolute; width: 70px; height: 70px; border-radius: 50%; background-color: rgb(192, 192, 192); left: 871px; top: 34.3005px;">
        </div>
        <div class="sf-ui-display"
            style="position: absolute; font-size: 26px; font-weight: 600; background-color: rgb(225, 225, 225); width: 304px; height: 31px; left: 959px; top: 39.3005px;">
        </div>
        <div class="sf-ui-text"
            style="position: absolute; font-size: 15px; font-weight: 500; background-color: rgb(222, 247, 255); width: 140.4px; height: 18px; left: 947.3px; top: 80.3005px;">
        </div>
        <style>
            .bootstrap-mock-springboard-view * {
                filter: contrast(0.65) brightness(1.2);
            }

            .bootstrap-mock-springboard-view .sf-ui-display {
                font-family: SFUIDisplay, Helvetica Neue, sans-serif;
            }

            .bootstrap-mock-springboard-view .sf-ui-text {
                font-family: SFUIText, Helvetica Neue, sans-serif;
            }

            body[apple-system-font-capable] .bootstrap-mock-springboard-view .sf-ui-display,
            body[apple-system-font-capable] .bootstrap-mock-springboard-view .sf-ui-text {
                font-family: system-ui, -apple-system, BlinkMacSystemFont;
            }
        </style>
        <div
            style="position: absolute;border-radius: 20%;height: 129.03px;width: 129.03px;left: 432.05px;top: 133.901px;background-color: rgb(102, 188, 249);">
            <div
                style="position: absolute; bottom: -10px; left: 50%; transform: translateX(-50%) translateY(100%); font-family: SFUIText, Helvetica, sans-serif; font-size: 13px; font-weight: 400; background-color: rgb(225, 225, 225); white-space: nowrap; width: 24px; height: 15px;">
            </div>
        </div>
        <div
            style="position: absolute; border-radius: 20%; height: 129.03px; width: 129.03px; left: 660.224px; top: 133.901px; background-color: rgb(204, 204, 191);">
            <div
                style="position: absolute; bottom: -10px; left: 50%; transform: translateX(-50%) translateY(100%); font-family: SFUIText, Helvetica, sans-serif; font-size: 13px; font-weight: 400; background-color: rgb(225, 225, 225); white-space: nowrap; width: 51px; height: 15px;">
            </div>
        </div>
        <div
            style="position: absolute; border-radius: 20%; height: 129.03px; width: 129.03px; left: 888.398px; top: 133.901px; background-color: rgb(239, 232, 231);">
            <div
                style="position: absolute; bottom: -10px; left: 50%; transform: translateX(-50%) translateY(100%); font-family: SFUIText, Helvetica, sans-serif; font-size: 13px; font-weight: 400; background-color: rgb(225, 225, 225); white-space: nowrap; width: 53px; height: 15px;">
            </div>
        </div>
        <div
            style="position: absolute; border-radius: 20%; height: 129.03px; width: 129.03px; left: 1116.57px; top: 133.901px; background-color: rgb(208, 196, 179);">
            <div
                style="position: absolute; bottom: -10px; left: 50%; transform: translateX(-50%) translateY(100%); font-family: SFUIText, Helvetica, sans-serif; font-size: 13px; font-weight: 400; background-color: rgb(225, 225, 225); white-space: nowrap; width: 40px; height: 15px;">
            </div>
        </div>
        <div
            style="position: absolute; border-radius: 20%; height: 129.03px; width: 129.03px; left: 1344.75px; top: 133.901px; background-color: rgb(201, 232, 252);">
            <div
                style="position: absolute; bottom: -10px; left: 50%; transform: translateX(-50%) translateY(100%); font-family: SFUIText, Helvetica, sans-serif; font-size: 13px; font-weight: 400; background-color: rgb(225, 225, 225); white-space: nowrap; width: 71px; height: 15px;">
            </div>
        </div>
        <div
            style="position: absolute; border-radius: 20%; height: 129.03px; width: 129.03px; left: 1572.92px; top: 133.901px; background-color: rgb(251, 241, 203);">
            <div
                style="position: absolute; bottom: -10px; left: 50%; transform: translateX(-50%) translateY(100%); font-family: SFUIText, Helvetica, sans-serif; font-size: 13px; font-weight: 400; background-color: rgb(225, 225, 225); white-space: nowrap; width: 34px; height: 15px;">
            </div>
        </div>
        <div
            style="position: absolute; border-radius: 20%; height: 129.03px; width: 129.03px; left: 432.05px; top: 359.703px; background-color: rgb(246, 249, 245);">
            <div
                style="position: absolute; bottom: -10px; left: 50%; transform: translateX(-50%) translateY(100%); font-family: SFUIText, Helvetica, sans-serif; font-size: 13px; font-weight: 400; background-color: rgb(225, 225, 225); white-space: nowrap; width: 63px; height: 15px;">
            </div>
        </div>
        <div
            style="position: absolute; border-radius: 20%; height: 129.03px; width: 129.03px; left: 660.224px; top: 359.703px; background-color: rgb(255, 170, 30);">
            <div
                style="position: absolute; bottom: -10px; left: 50%; transform: translateX(-50%) translateY(100%); font-family: SFUIText, Helvetica, sans-serif; font-size: 13px; font-weight: 400; background-color: rgb(225, 225, 225); white-space: nowrap; width: 37px; height: 15px;">
            </div>
        </div>
        <div
            style="position: absolute; border-radius: 20%; height: 129.03px; width: 129.03px; left: 888.398px; top: 359.703px; background-color: rgb(125, 239, 120);">
            <div
                style="position: absolute; bottom: -10px; left: 50%; transform: translateX(-50%) translateY(100%); font-family: SFUIText, Helvetica, sans-serif; font-size: 13px; font-weight: 400; background-color: rgb(225, 225, 225); white-space: nowrap; width: 53px; height: 15px;">
            </div>
        </div>
        <div
            style="position: absolute; border-radius: 20%; height: 129.03px; width: 129.03px; left: 1116.57px; top: 359.703px; background-color: rgb(77, 174, 248);">
            <div
                style="position: absolute; bottom: -10px; left: 50%; transform: translateX(-50%) translateY(100%); font-family: SFUIText, Helvetica, sans-serif; font-size: 13px; font-weight: 400; background-color: rgb(225, 225, 225); white-space: nowrap; width: 47px; height: 15px;">
            </div>
        </div>
        <div
            style="position: absolute; border-radius: 20%; height: 129.03px; width: 129.03px; left: 1344.75px; top: 359.703px; background-color: rgb(255, 193, 55);">
            <div
                style="position: absolute; bottom: -10px; left: 50%; transform: translateX(-50%) translateY(100%); font-family: SFUIText, Helvetica, sans-serif; font-size: 13px; font-weight: 400; background-color: rgb(225, 225, 225); white-space: nowrap; width: 72px; height: 15px;">
            </div>
        </div>
        <div
            style="position: absolute; border-radius: 20%; height: 129.03px; width: 129.03px; left: 1572.92px; top: 359.703px; background-color: rgb(121, 169, 129);">
            <div
                style="position: absolute; bottom: -10px; left: 50%; transform: translateX(-50%) translateY(100%); font-family: SFUIText, Helvetica, sans-serif; font-size: 13px; font-weight: 400; background-color: rgb(225, 225, 225); white-space: nowrap; width: 69px; height: 15px;">
            </div>
        </div>
    </div>
    <div class="task-wrapper overview-task-wrapper">
        <div class="wellcome-user" style="margin-top: 120px;">
            <div class="empty-avatar cn-empty-avatar" style="height: 148px; width: 148px;">
                <!-- <img [src]="auth?.profile?.fileUrl" *ngIf="auth?.profile?.fileUrl" /> -->
                <svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512"
                    xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path
                            d="m256 22c-135.31 0-245 109.69-245 245 0 20.904 2.625 41.193 7.551 60.564l13.711 39.393c38.248 85.48 124.033 145.043 223.738 145.043 135.31 0 245-109.69 245-245s-109.69-245-245-245z"
                            fill="#814aa0" />
                        <g>
                            <g>
                                <path
                                    d="m242.74 285.812-150.191-150.19-61.057 158.375 1.208 2.623 49.224 49.224 2.441 1.026z"
                                    fill="#62dbfb" />
                                <g fill="#01d0fb">
                                    <path d="m104.123 187.779 10.899-28.271-22.866-22.866-10.899 28.271z" />
                                    <path d="m42.391 265.726-10.899 28.271 22.866 22.866 10.899-28.271z" />
                                    <path d="m79.24 252.321 10.899-28.271-22.865-22.866-10.899 28.271z" />
                                </g>
                                <path
                                    d="m47.951 359.297-26.257-26.257-1.777-.363c-.003-.011-.011-.022-.015-.033 3.28 11.821 7.425 23.282 12.361 34.313l15.832-6.104z"
                                    fill="#62dbfb" />
                            </g>
                            <g>
                                <path
                                    d="m19.903 332.647c3.28 11.82 7.424 23.28 12.359 34.31l3.269-1.26 4.843-12.563-20.457-20.457c-.003-.01-.011-.02-.014-.03z"
                                    fill="#01d0fb" />
                            </g>
                            <g fill="#fed402">
                                <path
                                    d="m107.985 102.216 168.16 168.16c1.953 1.953 1.953 5.118 0 7.071l-18.056 18.056c-1.953 1.953-5.118 1.953-7.071 0l-168.16-168.16c-1.953-1.953-1.953-5.118 0-7.071l18.056-18.056c1.953-1.952 5.119-1.952 7.071 0z" />
                                <path d="m67.273 201.184 109.905 109.904 36.271-13.983-132.192-132.192z" />
                                <path d="m42.391 265.726 70.245 70.245 36.271-13.984-92.532-92.532z" />
                                <path
                                    d="m31.492 293.997-12.941 33.567c.435 1.712.895 3.415 1.366 5.113l28.177 28.177 36.271-13.984z" />
                            </g>
                            <path
                                d="m103.464 127.344c-1.953-1.953-1.953-5.118 0-7.071l11.289-11.289-6.768-6.768c-1.953-1.953-5.118-1.953-7.071 0l-18.056 18.056c-1.953 1.953-1.953 5.118 0 7.071l168.16 168.16c1.953 1.953 5.118 1.953 7.071 0l6.768-6.768z"
                                fill="#fac600" />
                        </g>
                        <g>
                            <path
                                d="m494.945 479.481-63.837-165.584-108.211 108.211 165.584 63.837c4.046 1.559 8.023-2.418 6.464-6.464z"
                                fill="#62dbfb" />
                            <path
                                d="m362.299 408.157 75.891-75.891-6.688-17.349-107.585 107.584 164.564 63.444c4.046 1.56 8.024-2.418 6.464-6.464l-5.73-14.864-125.178-48.26c-3.376-1.301-4.295-5.643-1.738-8.2z"
                                fill="#01d0fb" />
                            <g fill="#fe5694">
                                <path
                                    d="m295.789 405.126 118.336-118.336c1.953-1.953 5.118-1.953 7.071 0l15.68 15.68c1.953 1.953 1.953 5.118 0 7.071l-118.335 118.336c-1.953 1.953-5.118 1.953-7.071 0l-15.68-15.68c-1.953-1.953-1.953-5.119-.001-7.071z" />
                                <path d="m354.83 434.419 32.841 12.661 68.409-68.409-12.661-32.841z" />
                                <path d="m451.426 471.659 29.233-29.233-12.661-32.842-49.414 49.414z" />
                            </g>
                            <path
                                d="m316.464 412.197c-1.953-1.953-1.953-5.118 0-7.071l111.535-111.535-6.802-6.802c-1.953-1.953-5.118-1.953-7.071 0l-118.337 118.337c-1.953 1.953-1.953 5.119 0 7.071l15.68 15.68c1.953 1.953 5.118 1.953 7.071 0l6.802-6.802z"
                                fill="#fd3581" />
                        </g>
                        <g fill="#fac600">
                            <path
                                d="m248.468 196.924c-6.146-1.722-10.573-1.722-16.719 0-1.492.418-2.875-.965-2.457-2.457 1.722-6.146 1.722-10.573 0-16.719-.418-1.492.965-2.875 2.457-2.457 6.146 1.722 10.573 1.722 16.719 0 1.492-.418 2.875.965 2.457 2.457-1.722 6.146-1.722 10.573 0 16.719.417 1.492-.966 2.875-2.457 2.457z" />
                            <path
                                d="m312.468 333.924c-6.146-1.722-10.573-1.722-16.719 0-1.492.418-2.875-.965-2.457-2.457 1.722-6.146 1.722-10.573 0-16.719-.418-1.492.965-2.875 2.457-2.457 6.146 1.722 10.573 1.722 16.719 0 1.492-.418 2.875.965 2.457 2.457-1.722 6.146-1.722 10.573 0 16.719.417 1.492-.966 2.875-2.457 2.457z" />
                            <path
                                d="m451.468 246.924c-6.146-1.722-10.573-1.722-16.719 0-1.492.418-2.875-.965-2.457-2.457 1.722-6.146 1.722-10.573 0-16.719-.418-1.492.965-2.875 2.457-2.457 6.146 1.722 10.573 1.722 16.719 0 1.492-.418 2.875.965 2.457 2.457-1.722 6.146-1.722 10.573 0 16.719.417 1.492-.966 2.875-2.457 2.457z" />
                        </g>
                        <path
                            d="m226.384 101.676 12.441 4.219c.935.317 1.966.154 2.758-.437l10.529-7.857c1.993-1.487 4.826-.044 4.794 2.443l-.168 13.136c-.013.987.461 1.918 1.268 2.488l10.726 7.585c2.03 1.436 1.533 4.576-.842 5.314l-12.545 3.9c-.943.293-1.681 1.031-1.974 1.974l-3.9 12.545c-.738 2.374-3.878 2.872-5.314.842l-7.585-10.726c-.57-.806-1.5-1.28-2.488-1.268l-13.136.168c-2.486.032-3.93-2.801-2.443-4.794l7.857-10.529c.591-.791.754-1.823.437-2.758l-4.219-12.441c-.799-2.354 1.449-4.602 3.804-3.804z"
                            fill="#fe93bb" />
                        <path
                            d="m333.854 267.049 12.441 4.219c.935.317 1.966.154 2.758-.437l10.529-7.857c1.993-1.487 4.826-.044 4.794 2.443l-.168 13.136c-.013.987.461 1.918 1.268 2.488l10.726 7.585c2.03 1.436 1.533 4.576-.842 5.314l-12.545 3.9c-.943.293-1.681 1.031-1.974 1.974l-3.9 12.545c-.738 2.374-3.878 2.872-5.314.842l-7.585-10.726c-.57-.806-1.5-1.28-2.488-1.268l-13.136.168c-2.486.032-3.93-2.801-2.443-4.794l7.857-10.529c.591-.791.754-1.823.437-2.758l-4.219-12.441c-.799-2.354 1.449-4.602 3.804-3.804z"
                            fill="#fe93bb" />
                        <g>
                            <path
                                d="m280.286 224.535-3.206-13.628c-.379-1.613.621-3.228 2.233-3.607l27.859-6.553c1.366-.321 2.326-1.546 2.313-2.949l-.262-27.216c-.014-1.403.947-2.628 2.313-2.949l26.492-6.23c1.366-.321 2.327-1.546 2.313-2.949l-.264-27.213c-.014-1.403.947-2.628 2.313-2.949l43.43-10.211c1.613-.379 3.228.621 3.607 2.234l3.204 13.628c.379 1.613-.621 3.228-2.234 3.607l-27.854 6.549c-1.366.321-2.327 1.546-2.313 2.949l.264 27.212c.014 1.403-.947 2.628-2.313 2.949l-26.493 6.229c-1.366.321-2.327 1.546-2.313 2.949l.208 21.616c.045 4.677-3.158 8.76-7.71 9.83l-37.982 8.934c-1.611.381-3.226-.619-3.605-2.232z"
                                fill="#fac600" />
                        </g>
                        <path
                            d="m177.088 349.822 25.417 29.604c3.046 3.548 8.145 4.511 12.276 2.318l19.093-10.136c1.239-.658 2.769-.369 3.683.696l17.727 20.65c.914 1.065 2.444 1.354 3.683.696l35.429-18.65c1.46-.768 2.025-2.571 1.266-4.036l-6.511-12.555c-.768-1.481-2.597-2.051-4.07-1.268l-21.36 11.342c-1.239.658-2.769.369-3.683-.696l-17.727-20.65c-.914-1.065-2.444-1.354-3.683-.696l-24.04 12.763c-1.239.658-2.769.369-3.683-.695l-18.643-21.714c-1.079-1.257-2.973-1.401-4.23-.322l-10.622 9.12c-1.257 1.078-1.401 2.972-.322 4.229z"
                            fill="#01d0fb" />
                        <path
                            d="m171.986 118.912 29.604-25.417c3.548-3.046 4.511-8.145 2.318-12.276l-10.136-19.093c-.658-1.239-.369-2.769.696-3.683l20.65-17.727c1.065-.914 1.354-2.444.696-3.683l-18.65-35.429c-.768-1.46-2.571-2.025-4.036-1.266l-12.555 6.511c-1.481.768-2.051 2.597-1.268 4.07l11.342 21.36c.658 1.239.369 2.769-.696 3.683l-20.651 17.727c-1.065.914-1.354 2.444-.696 3.683l12.763 24.04c.658 1.239.369 2.769-.695 3.683l-21.714 18.643c-1.257 1.079-1.401 2.973-.322 4.23l9.12 10.622c1.079 1.257 2.973 1.401 4.23.322z"
                            fill="#05e39c" />
                        <path
                            d="m311.73 69.899-17.668 30.601c-1.155 2 .289 4.5 2.598 4.5h35.335c2.309 0 3.753-2.5 2.598-4.5l-17.668-30.601c-1.154-2-4.04-2-5.195 0z"
                            fill="#05e39c" />
                        <path
                            d="m380.029 209.18-9.849 9.849c-1.172 1.172-1.172 3.071 0 4.243l9.849 9.849c1.172 1.172 3.071 1.172 4.243 0l9.849-9.849c1.172-1.172 1.172-3.071 0-4.243l-9.849-9.849c-1.172-1.171-3.071-1.171-4.243 0z"
                            fill="#62dbfb" />
                        <path
                            d="m178.728 417.18-9.849 9.849c-1.172 1.172-1.172 3.071 0 4.243l9.849 9.849c1.172 1.172 3.071 1.172 4.243 0l9.849-9.849c1.172-1.172 1.172-3.071 0-4.243l-9.849-9.849c-1.172-1.171-3.071-1.171-4.243 0z"
                            fill="#fff" />
                        <path
                            d="m251.728 35.18-9.849 9.849c-1.172 1.172-1.172 3.071 0 4.243l9.849 9.849c1.172 1.172 3.071 1.172 4.243 0l9.849-9.849c1.172-1.172 1.172-3.071 0-4.243l-9.849-9.849c-1.172-1.171-3.071-1.171-4.243 0z"
                            fill="#62dbfb" />
                        <path d="m67.273 201.184 22.866 22.866 13.984-36.271-22.866-22.866z" fill="#fac600" />
                        <path d="m65.257 288.592 13.983-36.271-22.865-22.866-13.984 36.271z" fill="#fac600" />
                        <path
                            d="m18.551 327.564c.435 1.712.894 3.415 1.366 5.112l20.457 20.457 13.984-36.271-22.866-22.866z"
                            fill="#fac600" />
                        <path d="m403.269 431.483-32.842-12.661-15.597 15.597 13.857 5.342 18.984 7.319z"
                            fill="#fd3581" />
                        <path d="m467.023 456.062-32.842-12.662-15.597 15.598 32.842 12.661z" fill="#fd3581" />
                    </g>
                </svg>
            </div>

            <input #fileInput type="file" multiple accept='image/*' style="display:none;" />
            <div class="wellcome-text k-wellcome-text">
                <strong>Congratulations</strong>,
                {{auth?.user?.full_name}}

            </div>
            <p>You have completed your placement test successfully.</p>
            <a mat-button (click)="_signOut()" style="border: 1px solid #bf1f2f;
            color: #bf1f2f;
            margin-top: 24px;">Sign Out</a>
        </div>
    </div>
</ng-container>