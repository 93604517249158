<div class="bank-order">
    <p *ngFor="let row of PATH_B;let i=index">
        {{i+1}}.&nbsp;<span *ngIf="row?.name && !row?.textOptions" [innerHTML]="row?.name"></span>
        <app-display-math-type *ngIf="row?.textOptions" [textArray]="row?.textOptions">
        </app-display-math-type>
    </p>
</div>

<div class="s-order" *ngFor="let item of question?.answers;let i=index">
    {{i+1}}.
    <mat-form-field appearance="fill" class="fill-form-input">
        <mat-select 
        [disabled]="store?.process || store?.loading"
        [value]="question?.studentAnswer|findArrayAsnwer:item?.key" 
        [compareWith]="ds?.compareObject" (selectionChange)="onChanged($event,item)">
            <mat-option *ngFor="let row of PATH_B;let j=index;" [value]="row">
                <strong>{{j+1}}. </strong>
                <span *ngIf="row?.name && !row?.textOptions" [innerHTML]="row?.name"></span>
                <app-display-math-type *ngIf="row?.textOptions" [textArray]="row?.textOptions">
                </app-display-math-type>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>