<header id="header">
  <nav class="top-nav">
    <div class="nav-content">
      <div class="left-nav">
        <div class="brand-name" [routerLink]="['/']">
          <img src="../../../assets/images/LogoSPS.png" />
          <span>Sovannaphumi School · Testing Center</span>
        </div>
      </div>
      <div class="right-nav">
        <div class="nav-item-list">
          <countdown
            #cd
            class="countdown-test"
            *ngIf="testing?.countdown"
            (event)="onHandleEvent($event)"
            [config]="{ leftTime: testing?.countdown, format: 'HH:mm:ss' }"
          >
          </countdown>

          <button mat-button [disabled]="testing?.process" (click)="_signOut()">
            <div class="rows">
              <div class="avatar">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/sysanotta.appspot.com/o/no_image.svg?alt=media&token=f25af902-6c62-47e6-bdf3-48e3b0c6fe8c"
                  alt="Auxswot"
                />
              </div>
              <span>{{ student?.full_name }}</span>
            </div>
          </button>
          <button
            [disabled]="testing?.process || testing?.loading"
            mat-button
            class="mat-submit"
            (click)="_onSubmit()"
          >
            {{ store?.process ? "Submitting..." : "Submit" }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</header>
