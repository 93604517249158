<div class="doc-mcq" [ngClass]="question?.studentAnswer ? 'active' : ''">
  <div class="word-banks">
    <span class="bank-item" *ngFor="let item of wordBanks">
      <app-display-math-type [textArray]="item"> </app-display-math-type>
    </span>
  </div>
  <div class="fill-p" *ngFor="let row of question?.questions; let i = index">
    <span>{{ i + 1 }}.</span>
    <app-display-math-type [PATH_B]="wordBanks" [isExam]="true" [textArray]="row?.textQuestions"
      [studentAnswer]="row?.studentAnswer" [showAnswer]="true">
    </app-display-math-type>
    <!-- <div class="doc-s-answer">
      <app-display-math-type *ngIf="row?.studentAnswer?.length>0" [textArray]="row?.studentAnswer"> </app-display-math-type>
    </div> -->
    <mat-form-field appearance="fill" style="margin-left: 16px;">
      <mat-label>សូមជ្រើសរើសចម្លើយ</mat-label>
      <mat-select [disabled]="store?.process || store?.loading" class="fill-form-input"
        [compareWith]="ds?.compareObject" (selectionChange)="onChanged($event, row)">
        <mat-option *ngFor="let word of wordBanks" [value]="word[0]">
          <app-display-math-type [textArray]="word"> </app-display-math-type>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>