<div class="dialog-wrapper">
  <div class="dialog-header d-flex">
    <div class="title">
      Confirm Payment
    </div>
    <div class="flex1"></div>
    <a mat-dialog-close class="close-dialog" (click)="dialogRef.close('no')">
      <i class="material-icons">&#xE14C;</i>
    </a>
  </div>
  <div class="dialog-content">
    <div>
      <h3><strong>Cashier:</strong>{{data?.name}}</h3>
      <h3><strong>Balance:</strong> <span style="color:#228BE6; font-size:28px"> {{(data?.cash_in + data?.cash_out) | currency}}</span></h3>
    </div>
  </div>
  <div class="dialog-footer">
    <div class="flex1"></div>
    <a mat-dialog-close class="enbutton cancel m-r16" (click)="dialogRef.close('no')">cancel</a>
    <button class="enbutton primary m-r16" (click)="create()">Confirm</button>
  </div>
  <!-- <app-spinner *ngIf="store?.process"></app-spinner> -->
</div>