import { Component, OnInit } from '@angular/core';
import { AuthStore } from 'src/app/core/stores/auth.store';

@Component({
  selector: 'app-test-success',
  templateUrl: './test-success.component.html',
  styleUrls: ['./test-success.component.scss']
})
export class TestSuccessComponent implements OnInit {

  constructor(
    public auth: AuthStore

  ) { }

  ngOnInit(): void {
  }
  _signOut() {
    this.auth.signOut();
  }


}
