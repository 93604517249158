import { Router } from '@angular/router';
import { observable, action, computed, } from 'mobx';
import { Injectable, NgZone } from '@angular/core';
import { AngularFirestoreDocument } from '@angular/fire/firestore';
import 'firebase/firestore';
import { auth } from 'firebase/app';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { SCHOOL } from '../dummy/config';
import { pushToObject } from 'src/app/shared/services/mapping.service';

@Injectable({ providedIn: 'root' })
export class AuthStore {
  @observable remember: boolean = false;
  @observable isLogged: boolean = false;
  @observable process: boolean = false;
  @observable loading: boolean = true;
  @observable error: any;

  @observable user: any = null;
  @observable uid: any = null;
  @observable appName: any = SCHOOL.name;
  @observable storeKey: string = "sovannaphumi_school";
  @observable selectedCampus: any = null;

  constructor(
    private router: Router,
    private auth: AuthService,
    private ds: DataService,
    private ngZone: NgZone,
  ) {
    this.fetchCanActive()
  }

  @action
  async fetchUser() {
    const uid = (await this.auth.currentUserRef()).uid
    const doc = await this.ds.userRef(uid).get().toPromise();
    const item = pushToObject(doc);
    this.selectedCampus = item && item.campus;
    this.uid = uid;
    return item;
  }

  @observable fetchCanActiveRef: any = null;
  @action
  fetchCanActive(callback?) {
    this.error = null;
    this.loading = true;
    this.auth.canActiveRef().subscribe(async user => {
      if (user) {
        this.fetchCanActiveRef = this.ds.studentRef().doc(user.uid)
          .valueChanges()
          .subscribe(async (doc: any) => {
            if(doc){
              this.user = doc;
              this.uid = doc.key;
              this.selectedCampus = doc.campus;
              localStorage.setItem('user', JSON.stringify(this.user));
            }
    
            // JSON.parse(localStorage.getItem('user'));
            this.loading = false;
          })
      } else {
        this.signOut();
      }
      if (callback) return callback(user)
    })
  }


  @action
  signIn(email, password, callback) {
    this.process = true;
    this.error = null;
    this.auth.authRef().signInWithEmailAndPassword(`${email}@gmail.com`, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.process = false;
          this.user = result.user;
          this.uid = result.user.uid;
          localStorage.setItem('user', JSON.stringify(this.user));
          callback(result && result.user, true)
        });
      }).catch((error) => {
        this.error = "Invalid your email and password or access denied.";
        alert(error)
        callback(null, false)
      }).finally(() => {
        this.process = false;
      })
  }

  // Sign up with email/password
  @action
  signUp(email, password) {
    return this.auth.authRef().createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.setUserData(result.user);
        // this.sendVerificationMail();

      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Send email verfificaiton when new user sign up
  @action
  sendVerificationMail() {
    return this.user.sendEmailVerification()
      .then(() => {
        this.router.navigate(['verify-email-address']);
      })
  }

  // Reset Forggot password
  @action
  forgotPassword(passwordResetEmail) {
    return this.auth.authRef().sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error)
      })
  }

  // Returns true when user is looged in and email is verified
  @computed
  get isLoggedIn(): boolean {
    const user = localStorage.getItem('user');
    // return (user !== null && user.emailVerified !== false) ? true : false;
    return user !== null ? true : false;
  }

  // Sign in with Google
  @action
  googleAuth() {
    return this.authLogin(new auth.GoogleAuthProvider()).then(() => {
      this.router.navigate(['dashboard']);
    });
  }

  @action
  resetPassword(email) {
    return this.auth.authRef().sendPasswordResetEmail(email);
  }

  // Auth logic to run auth providers
  @action
  authLogin(provider) {
    return this.auth.authRef().signInWithPopup(provider)
      .then((result) => {
        this.setUserData(result.user);
      }).catch((error) => {
        window.alert(error)
      })
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  @action
  setUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.auth.userRef().doc(user.uid);
    const userData: any = {
      key: user.uid,
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  // Sign out 
  @action
  signOut() {
    this.error = null;
    this.fetchCanActiveRef && this.fetchCanActiveRef.unsubscribe();
    this.auth.authRef().signOut().then(() => {
      this.user = null;
      this.uid = null;
      this.router.navigate(["/auth/login"]);
      localStorage.removeItem('user');
    })
  }

}
