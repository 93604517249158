import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findAnswerKey'
})
export class FindAnswerKeyPipe implements PipeTransform {

  transform(key: any, value: any,isFill:boolean, ...args: unknown[]): unknown {
    if (Array.isArray(value)) {
      return value.filter(m => m.key === key).length > 0;
    }
    return value && value.key === key;
  }

}
