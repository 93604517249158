import { Component, Input, OnInit } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Component({
  selector: 'main-sidebar',
  templateUrl: './main-sidebar.component.html',
  styleUrls: ['./main-sidebar.component.scss']
})
export class MainSidebarComponent implements OnInit {
  @Input() menu: any = null;
  @Input() appName: any = null;

  expansions = {
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false,
    nine: false,
    ten: false,
    eleven: false,
    twelve: false,
  };
  toggleSidebar: boolean = true;

  constructor(
    protected localStorages: LocalStorage,
  ) { }

  ngOnInit() {
    const toggleSidebar = localStorage.getItem("toggleSidebar");
    this.toggleSidebar = JSON.parse(toggleSidebar);
    if (!this.toggleSidebar) {
      let body = document.getElementsByClassName("page-wrapper")[0];
      body.classList.toggle("toggled-sidebar");
    }
    // const expansions = JSON.parse(localStorage.getItem(`swotify_expansions_${this.appName.key}`));
    // if (expansions) {
    //   this.expansions = expansions
    // }
  }

  onToggleSidebar() {
    let body = document.getElementsByClassName("page-wrapper")[0];
    body.classList.toggle("toggled-sidebar");
    this.toggleSidebar = !this.toggleSidebar;
    localStorage.setItem('toggleSidebar', JSON.stringify(this.toggleSidebar));

  }

  onOpened(i) {
    const isOpen = true;
    switch (i) {
      case 1:
        this.expansions.one = isOpen;
        break;
      case 2:
        this.expansions.two = isOpen;
        break;
      case 3:
        this.expansions.three = isOpen;
        break;
      case 4:
        this.expansions.four = isOpen;
        break;
      case 5:
        this.expansions.five = isOpen;
        break;
      case 6:
        this.expansions.six = isOpen;
        break;
      case 7:
        this.expansions.seven = isOpen;
        break;
      case 8:
        this.expansions.eight = isOpen;
        break;
      case 9:
        this.expansions.nine = isOpen;
        break;
      case 10:
        this.expansions.ten = isOpen;
        break;
    }
    localStorage.setItem(`swotify_expansions_${this.appName.key}`, JSON.stringify(this.expansions));
  }

  onClosed(i) {
    const isOpen = false;
    switch (i) {
      case 1:
        this.expansions.one = isOpen;
        break;
      case 2:
        this.expansions.two = isOpen;
        break;
      case 3:
        this.expansions.three = isOpen;
        break;
      case 4:
        this.expansions.four = isOpen;
        break;
      case 5:
        this.expansions.five = isOpen;
        break;
      case 6:
        this.expansions.six = isOpen;
        break;
      case 7:
        this.expansions.seven = isOpen;
        break;
      case 8:
        this.expansions.eight = isOpen;
        break;
      case 9:
        this.expansions.nine = isOpen;
        break;
      case 10:
        this.expansions.ten = isOpen;
        break;
    }
    localStorage.setItem(`swotify_expansions_${this.appName.key}`, JSON.stringify(this.expansions));
  }
}
