import { observable, action, } from 'mobx';
import { Injectable } from '@angular/core';
import 'firebase/firestore';
import { DataService } from '../services/data.service';
import { academicTrainingProgramObj, academicYearObj, ageFromDateOfBirthday, campusObj, getAge, orderBy, pushToArray, pushToObject, shiftObj, studentObj, schoolObj, timestampToDate, toCapitalize } from 'src/app/shared/services/mapping.service';
import { AuthService } from '../services/auth.service';
import { ConvertService, createUniqueKey, generate_serialID, incrementRef, pageKey, toDateCalendar, toDateKey } from 'src/app/shared/services/convert.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SUBJECT_DATA } from '../dummy/config';
import * as firebase from 'firebase/app';
import { TestFeeStatus } from '../dummy/stauts';


@Injectable({ providedIn: 'root' })
export class Register {

  constructor(
    private db: DataService,
    private auth: AuthService,
    private snackBar: MatSnackBar,
  ) { }

  maxValue: number | null = null;
  @observable spsCampusList: any[] = [];
  @observable shift: any[] = [];
  @observable spsCampusListRef;
  @observable shiftRef;
  @action
  async spsCampusEvent() {
    this.spsCampusListRef = await this.db.storeRef().collection("campus").valueChanges().subscribe(doc => {
      this.spsCampusList = orderBy(doc, 'name');
    })
    this.shiftRef = await this.db.spsShift().valueChanges().subscribe(doc => {
      this.shift = doc;
    })
  }

  @observable programFromCampus: any[] = [];
  @observable programFromCampusRef;
  @action
  async spsProgramFromCampus(campusDoc) {
    this.programFromCampusRef = await this.db.spsCampusCollection().doc(campusDoc).collection('training_programs').valueChanges().subscribe(doc => {
      this.programFromCampus = doc
    })
  }

  @observable programLevel: any[] = [];
  @action
  async spsProgramLevel(campusDoc, programDoc) {
    if (campusDoc != undefined) {
      const level = await this.db.spsCampusCollection().doc(campusDoc).collection('training_levels', ref => ref.where('program.key', '==', programDoc)).get().toPromise();
      const levelArray = pushToArray(level)
      const levelOrder = orderBy(levelArray, 'order');
      this.programLevel = levelOrder;
    }
  }

  max_value: number;
  @observable loadingRegister: boolean = false;
  @observable isKGETesting;
  @observable isIEPTesting;
  @observable isTestingChinese;
  @observable testingAccount;
  @action
  async registerOnlineTesting(data, callback?) {

    this.loadingRegister = true;
    const batch = this.db.batch();
    const newKey = this.db.createId()
    const birthDateKey = ConvertService.toDateKey(data.birthDate)
    const uniqueKey = createUniqueKey(data.firstName, data.lastName, birthDateKey, data.gender?.text || "");

    const environment = pushToObject(await this.db.configRef().get().toPromise());
    const testingAccount = pushToArray(await this.db.accountTestingRef(uniqueKey));
    const account = testingAccount.length > 0 ? testingAccount[0] : null;
    const isExpired = account ? getAge(timestampToDate(account.create_date)) > 1 ? true : false : true;

    const testingOption: any = pushToObject(await this.db.testingOptionRef().get().toPromise());

    if (!isExpired) {
      this.snackBar.open('Already Resister Test', "Done", { duration: 2000 });
      this.loadingRegister = false;
      return;
    }
    const { serial_number } = testingOption
    checkExitAuth(serial_number, (result => {
      const PUC_ID = generate_serialID(result);
      this.isKGETesting = testingOption.test_KGE.key === data.program.key;
      this.isIEPTesting = testingOption.test_IEP.key === data.program.key;
      this.isTestingChinese = testingOption.test_CHINESE === data.program.key;

      this.auth.authRef().createUserWithEmailAndPassword(`${PUC_ID}@gmail.com`, "016555392").then((user) => {
        const studentData: any = {
          key: user.user.uid,
          unique_code: null,
          first_name: data.firstName.toUpperCase(),
          last_name: data.lastName.toUpperCase(),
          khmer_first_name: data.khFirstName,
          khmer_last_name: data.khLastName,
          full_name: data.lastName.toUpperCase() + ' ' + data.firstName.toUpperCase(),
          address: null,
          gender: data.gender,
          puc_id: null,
          serial_id: PUC_ID,
          recent_test_key: newKey,
          is_puc_student: false,
          dob: toDateCalendar(data.birthDate),
          dobKey: toDateKey(toDateCalendar(data.birthDate)),
          page_key: pageKey(),
          mobile_phone: data.phoneNumber,
          email: null,
          email_address: null,
          pob: null,
          marital_status: null,
          nationality: null,
          home_address: null,
          work_place: null,
          position: null,
          parent_name: null,
          spouse_name: null,
          emergency_person_name: null,
          emergency_relationship: null,
          emergency_address: null,
          emergency_phone: null,
          english_school_name: null,
          english_school_province: null,
          english_school_year: null,
          high_school_name: null,
          high_school_province: null,
          high_school_year: null,
          college_school_name: null,
          college_school_degree: null,
          college_school_major: null,
          college_school_year: null,
          university_school_name: null,
          university_school_degree: null,
          university_school_major: null,
          university_school_year: null,
          graduate_school_name: null,
          graduate_school_degree: null,
          graduate_school_major: null,
          graduate_school_year: null,
          education_type_at_puc: null,
          status: { text: "Active", key: 1 },
          program_general: null,
          create_date: new Date(),
          create_date_key: toDateKey(new Date()),
          create_by: null,
          prepaid: null,
          scholarship: null,
          program_academic: null,
          is_student_PSIS: false,
          is_student_institute: true,
          is_student_academic: false,
          campus: campusObj(data.campus),
          campusKey: data.campus.key,
          schoolKey: SPS_SCHOOL.key,
          school: schoolObj(SPS_SCHOOL)
        };

        const testingData = {
          key: newKey,
          khmer_first_name: data.khFirstName,
          khmer_last_name: data.khLastName,
          first_name: toCapitalize(data.firstName),
          last_name: toCapitalize(data.lastName),
          full_name: toCapitalize(`${data.lastName} ${data.firstName}`),
          dob: toDateCalendar(data.birthDate),
          gender: data.gender,
          mobile_phone: data.phoneNumber,
          note: data.remark,
          price: testingOption?.testing_fee,
          student: studentObj(studentData),
          isPaidTest: true,
          serial_id: PUC_ID,
          admission_date_key: toDateKey(new Date()),
          create_date_key: toDateKey(new Date()),
          status: { key: 1, text: "Active" },
          payment_type: null,
          subject_type: (this.isIEPTesting || this.isKGETesting) ? SUBJECT_DATA.khmer : null,
          admission_date: firebase.firestore.FieldValue.serverTimestamp(),
          create_date: firebase.firestore.FieldValue.serverTimestamp(),
          campus: campusObj(data.campus),
          shift: shiftObj(data.shift),
          program: academicTrainingProgramObj(data.program),
          programLevel: data.level || null,
          gradeKey: data.level?.gradeKey || null,
          puc_id: null,
          used: false,
          onlineTesting: true,
          page_key: pageKey(new Date()),
          isPaid: TestFeeStatus.unpaid,
          interview: false,
          unique_code: uniqueKey,
          noTakeTest: ageFromDateOfBirthday(data.birthDate) < 6 ? true : false,
          campusKey: data.campus.key,
          schoolKey: SPS_SCHOOL.key,
          school: schoolObj(SPS_SCHOOL),
          age: getAge(toDateCalendar(data.birthDate)),
          admission_term: academicYearObj(data.program.programOption.key === 1 ? environment.year : environment.term)
        }

        const studentAccount = {
          key: studentData.key,
          create_date: new Date(),
          create_by: null,
          status: { text: "Active", key: 1 },
          puc_id: studentData.puc_id,
          email: null,
          fileUrl: null,
          displayName: studentData.full_name,
          studentKey: studentData.key,
          phone: studentData.mobile_phone,
          token: null,
          pinCode: null,
          student: studentObj(studentData),
          campus: campusObj(data.campus),
          campusKey: data.campus.key,
          schoolKey: SPS_SCHOOL.key,
          school: schoolObj(SPS_SCHOOL)
        };

        const sysOptionRef = this.db.testingOptionFireRef();
        const testingOnlineRef = this.db.testingFireRef().doc(testingData.key)
        const studentRef = this.db.studentFireRef().doc(studentData.key);
        const batchStudentAccountRef = this.db.studentAccountFireRef().doc(studentData.key)

        // batch.update(sysOptionRef, { serial_number: incrementRef(1) });
        batch.update(sysOptionRef, { serial_number: result + 1 });

        batch.set(studentRef, studentData);
        batch.set(testingOnlineRef, testingData);
        batch.set(batchStudentAccountRef, studentAccount);

        batch.commit().then(() => {
          this.loadingRegister = false;
          this.testingAccount = testingData;
          callback(true, testingData)

        }).catch(e => {
          callback(false, "")
          this.loadingRegister = false
        });
      })

    }));



  }



  @observable studentProfileSignUpLoading: boolean = false;
  @observable studentProfileSignUpData: any;
  @action
  async studentProfileSignUp(id, callback) {
    this.studentProfileSignUpLoading = true;
    await this.db.accountTestingRefWithoutKey().doc(id).valueChanges().subscribe(doc => {
      callback(doc);
    })
    this.studentProfileSignUpLoading = false;
  }

}

export const SPS_SCHOOL = {
  key: "sovannaphumi_school",
  file: {
    downloadUrl: "https://firebasestorage.googleapis.com/v0/b/spscloud-e9e6a.appspot.com/o/schools%2FLogo-SPS.png?alt=media&token=f0383f17-152c-4b86-b3c0-edad96ca2002",
    filename: "schools/Logo-SPS.png",
    name: "Logo-SPS.png",
  },
  khName: "សាលារៀនសុវណ្ណភូមិ",
  name: "Sovannaphumi School",
  primary_gradient_colors: ['#a21a2d', '#bc1e37', "#e95130"],
  sub_gradient_color: ['#a21a2d', '#bc1e37', "#e95130"],
  primary_color: "#BA2031",
  copy_right: "copyRight",
  logo: './modules/sovannaphum/Logo-SPS.png',
  event_icon: './modules/sovannaphum/event.png',
  partner_icon: './modules/sovannaphum/partner.png',
  holiday_icon: './modules/sovannaphum/holiday.png',
  feedback_icon: './modules/sovannaphum/feedback.png',
  term_icon: './modules/sovannaphum/term.png',
  alert_school: "sovannaphumi",
  about_us: "aboutSps",
  label_w: './modules/sovannaphum/logo-label-w.png',
  label_b: './modules/sovannaphum/logo-label-b.png',
  label_r: './modules/sovannaphum/logo-label-r.png',
  cover_login: './modules/sovannaphum/cover-login.png',
  school_website: "https://www.sovannaphumi.edu.kh/",
  facebook: "https://www.facebook.com/SovannaphumiSchool",
  youtube: "https://www.youtube.com/user/sovannaphumischool",
  more_news: "moreNews",
}

function checkExitAuth(serial: number, callback: (result: number) => void) {
  const PUC_ID = generate_serialID(serial);
  firebase.auth().fetchSignInMethodsForEmail(`${PUC_ID}@gmail.com`)
    .then(async function (signInMethods) {
      if (signInMethods.length > 0) {
        const newID = serial + 1
        // console.log('User already exists', PUC_ID)
        return checkExitAuth(newID, callback);
      } else {
        // console.log('User does not exist, proceed with creating new user');
        callback(serial);
        return serial;
      }

    })
}
