import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobxAngularModule } from 'mobx-angular';
import { MaterialModule } from './ng-material/material.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimaryButtonComponent } from './components/primary-button/primary-button.component';
import { NguCarouselModule } from '@ngu/carousel';
import { ConfirmPaymentModalComponent } from './components/confirm-payment-modal/confirm-payment-modal.component';
import { DeleteComponent } from './components/delete/delete.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { EmptyComponent } from './components/empty/empty.component';
import { PucButtonComponent } from './components/puc-button/puc-button.component';
import { ConfirmSuccessComponent } from './components/confirm-success/confirm-success.component';
import { ConfirmDeleteComponent } from './components/confirm-delete/confirm-delete.component';
import { ConfirmApprovalComponent } from './components/confirm-approval/confirm-approval.component';
import { HeaderTabsComponent } from './components/header-tabs/header-tabs.component';
import { EmptyDataComponent } from './components/empty-data/empty-data.component';
import { McqComponent } from './components/mcq/mcq.component';
import { CompletedAnswerPipe } from './pipes/completed-answer.pipe';
import { KatexModule } from 'ng-katex';
import { DisplayMathTypeComponent } from './components/display-math-type/display-math-type.component';
import { FindAnswerKeyPipe } from './pipes/find-answer-key.pipe';
import { MatchingExerciseComponent } from './components/matching-exercise/matching-exercise.component';
import { FillInTheBlanksComponent } from './components/fill-in-the-blanks/fill-in-the-blanks.component';
import { FindAnswerPipe } from './pipes/find-answer.pipe';
import { SentenceOrderComponent } from './components/sentence-order/sentence-order.component';
import { TrueFalseExerciseComponent } from './components/true-false-exercise/true-false-exercise.component';
import { SentenceOrderExerciseComponent } from './components/sentence-order-exercise/sentence-order-exercise.component';
import { FindArrayAsnwerPipe } from './pipes/find-array-asnwer.pipe';
import { AudioExerciseComponent } from './components/audio-exercise/audio-exercise.component';
import { CountdownModule } from 'ngx-countdown';
import { ShuffleArrayPipe } from './pipes/shuffle-array.pipe';

const components = [
  PageNotFoundComponent,
  PrimaryButtonComponent,
  ConfirmPaymentModalComponent,
  DeleteComponent,
  SpinnerComponent,
  EmptyComponent,
  PucButtonComponent,
  ConfirmSuccessComponent,
  ConfirmDeleteComponent,
  ConfirmApprovalComponent,
  HeaderTabsComponent,
  EmptyDataComponent,
  McqComponent,
  CompletedAnswerPipe,
  DisplayMathTypeComponent,
  FindAnswerKeyPipe,
  MatchingExerciseComponent,
  FillInTheBlanksComponent,
  FindAnswerPipe,
  SentenceOrderComponent,
  TrueFalseExerciseComponent,
  SentenceOrderExerciseComponent,
  FindArrayAsnwerPipe,
  AudioExerciseComponent,
  ShuffleArrayPipe,

];
const modules = [
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  CommonModule,
  MaterialModule,
  MobxAngularModule,
  NguCarouselModule,
  KatexModule,
  CountdownModule,
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    ...modules,
  ],
  exports: [
    ...components,
    ...modules,

  ],
  providers: [

  ]
})
export class SharedModule { }
