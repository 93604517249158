<div class="doc-audio-items">
    <div class="doc-audio" *ngFor="let question of PATH_B">
        <button [disabled]="DISABLED" (click)="onPlay(question?.files?.downloadUrl)" mat-button mat-icon-button class="mat-audio">
            <mat-icon>volume_up</mat-icon>
        </button>
        <mat-form-field class="fill-form-input">
            <mat-select 
            [value]="question?.studentAnswer" 
            [disabled]="store?.process || store?.loading || DISABLED"
            [compareWith]="ds?.compareObject" 
            (selectionChange)="onChanged($event,question)">
                <mat-option *ngFor="let row of PATH_A" [value]="row">
                    <span *ngIf="row?.name && !row?.textOptions" [innerHTML]="row?.name"></span>
                    <app-display-math-type *ngIf="row?.textOptions" [textArray]="row?.textOptions">
                    </app-display-math-type>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>