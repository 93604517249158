import { DataService } from './../services/data.service';
import { action, observable, } from "mobx";
import { Injectable } from "@angular/core";
import { PresenceService } from '../services/presence.service';

@Injectable({ providedIn: 'root' })
export class Environment {
  @observable user = null;
  @observable config = null;
  @observable academicEnv = null;
  @observable loading = false;
  @observable academic = null;
  @observable process = false;

  @observable timeLeft: any = null;
  @observable serverDateTime: any = null;
  constructor(
    private ds: DataService,
    private presence: PresenceService,
  ) { }

  @action
  async fetchTestingOption(uid) {
    const options = await this.ds.testingOption().get().toPromise();
    this.presence.getPresence(uid).subscribe((doc: any) => {
      this.serverDateTime = new Date(doc.timestamp)
    })

  }


}
