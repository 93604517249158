<header id="header">
    <nav class="top-nav">
        <div class="nav-content">
            <div class="left-nav">
                <div class="brand-name" [routerLink]="['/']">
                    <img src="../../../assets/images/LogoSPS.png" />
                    <span>Sovannaphumi School · Testing Center</span>
                </div>
            </div>
            <div class="right-nav">
                <button mat-icon-button [matMenuTriggerFor]="menuuser" class="cs-mat-menu-item"
                    style="margin-right:0 !important">
                    <div class="click-avatar">
                        <img src="https://firebasestorage.googleapis.com/v0/b/sysanotta.appspot.com/o/no_image.svg?alt=media&token=f25af902-6c62-47e6-bdf3-48e3b0c6fe8c"
                            alt="Auxswot">
                    </div>
                </button>
                <mat-menu #menuuser="matMenu" class="cs-matmenu-content auto user-menu-content"
                    backdropClass="cs-matmenu-backdrop">
                    <div class="mat-menu-bar" (click)="$event.stopPropagation();">
                        <div class="d-flex">
                            <div class="mat-user-avatar">
                                <img src="https://firebasestorage.googleapis.com/v0/b/sysanotta.appspot.com/o/no_image.svg?alt=media&token=f25af902-6c62-47e6-bdf3-48e3b0c6fe8c"
                                    alt="Auxswot">
                            </div>
                            <div class="mat-user-info">
                                <h3>{{store?.user?.full_name}}</h3>
                                <p *ngIf="store?.user?.emai">{{store?.user?.email}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="mat-menu-body">
                        <mat-list>
                            <mat-list-item (click)="_signOut()">
                                <mat-icon mat-list-icon class="app-icon black">input</mat-icon>
                                <div mat-line class="d-flex">
                                    Sign Out
                                </div>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </mat-menu>
            </div>
        </div>
    </nav>
</header>