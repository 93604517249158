import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { SecureInnerPagesGuard } from './core/guards/secure-inner-pages.guard';
import { OverviewLayoutComponent } from './layouts/overview-layout/overview-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { TestSuccessComponent } from './modules/test-success/test-success.component';
import { RegisterSuccessComponent } from './modules/register-success/register-success.component';

const routes: Routes = [
  {
    path: 'auth', 
    canActivate: [SecureInnerPagesGuard],
    component: AuthLayoutComponent,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    canActivate: [AuthGuard], 
    component: OverviewLayoutComponent,
    loadChildren: () => import('./modules/overview/overview.module').then(m => m.OverviewModule)
  },
  {
    path: 'registerSuccess',
    canActivate: [AuthGuard], 
    component: RegisterSuccessComponent,
    loadChildren: () => import('./modules/register-success/register-success.module').then(m => m.RegisterSuccessModule)
  },
  {
    path: 'submited',
    canActivate: [AuthGuard], 
    component: HomeLayoutComponent, children:[
      { path: '', component: TestSuccessComponent }
    ]
  },
  { path: 'registerSuccess', loadChildren: () => import('./modules/register-success/register-success.module').then(m => m.RegisterSuccessModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
