<ng-container *ngFor="let item of items">
    <span class="1" [ngClass]="{'border-fill':item?.isFill?true:false,'no-border':hideBorder}"
        *ngIf="item?.type?.key===1">
        <span [ngClass]="canEdit?'match-text':''" (click)="_onText(item)">
            <span class="txt-nor" *ngIf="!isExam && item?.isFill">{{item?.text}}</span>
            <span class="txt-nor" *ngIf="!item?.isFill">{{item?.text}}</span>

            <span *ngIf="item?.isFill && showAnswer && (!studentAnswer || studentAnswer?.length===0)"
                style="display: inline-block;" class="doc-s-answer-line">
                &nbsp;
            </span>

            <span *ngIf="item?.isFill && showAnswer && studentAnswer?.length>0" style="display: inline-block;"
                class="doc-s-answer">
                <app-display-math-type *ngIf="studentAnswer?.length>0" [textArray]="studentAnswer">
                </app-display-math-type>
            </span>
        </span>
    </span>

    <span class="2" [ngClass]="{'border-fill':item?.isFill?true:false,'no-border':hideBorder}"
        *ngIf="item?.type?.key===2">
        <ng-katex *ngIf="!isExam && item?.isFill" style="font-size: 21px;" [equation]="item?.text"></ng-katex>
        <ng-katex *ngIf="!item?.isFill" style="font-size: 21px;" [equation]="item?.text"></ng-katex>
        <span *ngIf="item?.isFill && showAnswer && (!studentAnswer || studentAnswer?.length===0)"
            style="display: inline-block;" class="doc-s-answer-line">
            &nbsp;
        </span>
        <span *ngIf="item?.isFill && showAnswer && studentAnswer?.length>0" style="display: inline-block;"
            class="doc-s-answer">
            <app-display-math-type *ngIf="studentAnswer?.length>0" [textArray]="studentAnswer">
            </app-display-math-type>
        </span>
    </span>

    <span [ngClass]="canEdit?'match-text':''" (click)="_onText(item)" *ngIf="item?.type?.key===3">
        <span class="border-fill" [ngClass]="{'no-border':hideBorder}" [innerHTML]="item?.text"></span>
    </span>

    <span [ngClass]="canEdit?'match-text':''" (click)="_onLaText(item)" *ngIf="item?.type?.key===4">
        <span class="border-fill" [ngClass]="{'no-border':hideBorder}">
            <ng-katex style="font-size: 21px;" [equation]="item?.text"></ng-katex>
        </span>
    </span>

    <span [ngClass]="canEdit?'match-text':''" (click)="_onBreak(item)" *ngIf="canEdit && item?.type?.key===10">
        <span class="material-icons">
            keyboard_return
        </span>
    </span>
    <br *ngIf="item?.type?.key===10" />
</ng-container>