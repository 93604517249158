import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { Router } from '@angular/router';
import { Environment } from 'src/app/core/stores/environment';
import { SCHOOL } from 'src/app/core/dummy/config';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  formFocus = false;
  yPosition = 'below';
  school_name = SCHOOL.name
  campusList = [];
  constructor(
    public store: AuthStore,
    public env: Environment,
  ) { }

  ngOnInit() {
  }

  _onFocus(yes) {
    this.formFocus = yes;
  }

  _signOut() {
    this.store.signOut();
  }


}
