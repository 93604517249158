import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { toJS } from 'mobx';
import { promise } from 'selenium-webdriver';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private db: AngularFirestore,
  ) { }

  testingGepRef() {
    return this.db.collection('testing_gep', ref => ref.orderBy("created_at"))
  }

  testingKindergartenRef() {
    return this.db.collection('testing_kindergarten')
  }

  testingIEPRef(subject: any, gradeKey: any) {
    return this.db.collection('testing_kge', ref => ref
      .where("subject.key", "==", subject.key)
      .where("programLevelKey", "==", gradeKey)
      .orderBy("created_at")
    )
  }

  testingKGERef() {
    return this.db.collection('testing_kge')
  }

  testingKGEProgramRef(examType: any, subject: any, programLevel: any) {
    return this.db.collection('testing_kge', ref => ref
      .where("exam_type.key", "==", examType.key)
      .where("subject.key", "==", subject.key)
      .where("programLevelKey", "==", programLevel.gradeKey)
      .orderBy("created_at"))
  }

  userRef(uid) {
    return this.db.collection('users').doc(uid);
  }

  testingAccountRef(testingKey) {
    return this.db.collection('testing').doc(testingKey)
  }

  studentTestingRef(uid) {
    return this.db.collection('testing', ref => ref.where('student.key', '==', uid).where('used', '==', false).orderBy('create_date', 'desc').limit(1))
  }

  testingAccountFireRef(testingKey) {
    return this.db.firestore.collection('testing').doc(testingKey)
  }

  studentFireRef() {
    return this.db.firestore.collection('students')
  }

  studentRef() {
    return this.db.collection('students')
  }

  testingOption() {
    return this.db.collection('testing_options').doc('general')
  }

  firestore() {
    return this.db.firestore;
  }

  batch() {
    return this.db.firestore.batch();
  }

  createId() {
    return this.db.createId();
  }

  serverTimestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  usersRef() {
    return this.db.collection('users')
  }


  compareObject(o1: any, o2: any): boolean {
    if (o2 && o1) {
      return o1.key === o2.key;
    }
    return false;
  }

  compareObjectArray(o1: any, o2: any): boolean {
    if (o2 && o1) {
      return o1.key === o2.key;
    }
    return false;
  }

  compareTextObject(o1: any, o2: any): boolean {
    if (o2 && o1) {
      return o1.text === o2.text;
    }
    return false;
  }

  spsCampusCollection() {
    return this.db.collection('campus');
  }

  spsShift() {
    return this.db.collection('academics_shift');
  }

  storeRef() {
    return this.db.collection('stores').doc('sovannaphumi_school');
  }

  configRef(){
    return this.db.collection('stores').doc('sovannaphumi_school').collection('academic_environment').doc('academic_environment');
  }

  testingOptionRef() {
    return this.db.collection("testing_options").doc('general');
  }

  testingOptionFireRef() {
    return this.firestore().collection("testing_options").doc('general');
  }

  accountTestingRef(uniqueKey) {
    return this.db.collection('testing',ref=>ref.where("unique_code", "==", uniqueKey).orderBy("create_date",'desc').limit(1)).get().toPromise();
  }

  accountTestingRefWithoutKey() {
    return this.db.collection('testing')
  }

  testingFireRef() {
    return this.firestore().collection('testing')
  }

  studentProfileRef() {
    return this.db.collection('students')
  }
  studentAccountRef() {
    return this.db.collection('student_accounts')
  }
  studentAccountFireRef() {
    return this.firestore().collection('student_accounts')
  }

}
