import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { toJS } from 'mobx';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-display-math-type',
  templateUrl: './display-math-type.component.html',
  styleUrls: ['./display-math-type.component.scss']
})
export class DisplayMathTypeComponent implements OnInit {
  @Input() textArray: any[];
  @Input() canEdit: boolean;
  @Input() isExam: boolean;
  @Input() hideBorder: boolean;

  @Output() onText = new EventEmitter()
  @Output() onLaText = new EventEmitter()
  @Output() onBreak = new EventEmitter()

  @Input() studentAnswer:any;
  @Input() showAnswer:boolean;
  @Output() onStudentSelectedAnswer = new EventEmitter();
  @Input() PATH_B: any[] = [];

  items: any[] = []
  constructor(public ds: DataService) { }

  ngOnInit() {
    this.items = Array.isArray(this.textArray) ? this.textArray : [{ key: 1, text: this.textArray }]
  }

  onChanged(event, item) {
    this.onStudentSelectedAnswer.emit({ event, item })
  }

  _onText(text) {
    if (!this.canEdit) return;
    this.onText.emit(text)
  }

  _onLaText(text) {
    if (!this.canEdit) return;
    this.onLaText.emit(text)
  }

  _onBreak(text) {
    if (!this.canEdit) return;
    this.onBreak.emit(text)
  }
}
