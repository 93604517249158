import { Component, Input, OnInit } from '@angular/core';
import { toJS } from 'mobx';
import { DataService } from 'src/app/core/services/data.service';
import { TestingStore } from 'src/app/core/stores/testing.store';
import { orderBy, shuffleArray } from '../../services/mapping.service';

@Component({
  selector: 'app-sentence-order-exercise',
  templateUrl: './sentence-order-exercise.component.html',
  styleUrls: ['./sentence-order-exercise.component.scss']
})
export class SentenceOrderExerciseComponent implements OnInit {
  @Input() question: any;
  @Input() group: any;
  @Input() index: number;

  PATH_B: any[] = [];
  constructor(public store: TestingStore,
    public ds: DataService) { }

  ngOnInit(): void {
    this.PATH_B = orderBy(this.question.answers,"shuffleOrder")
  }

  onChanged(event,answerFill) {
    const { value } = event;
    const { testingAccount } = this.store;
    this.store.studentSelectedAnswer(testingAccount.key, this.group.key, this.question.key, value, this.group.type.key,answerFill.key)
  }
}
