import { DataService } from './../services/data.service';
import { action, observable, toJS, } from "mobx";
import { Injectable } from "@angular/core";
import { ageFromDateOfBirthday, orderBy, pushToArray, pushToObject, TimeLeft, timestampToDate, updateObjArray } from 'src/app/shared/services/mapping.service';
import { getServerTime, PresenceService } from '../services/presence.service';
import _, { shuffle } from "lodash";
import { ARRAY_SUBJECT_DATA, SUBJECT_DATA } from '../dummy/config';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { ConvertService } from 'src/app/shared/services/convert.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class TestingStore {
    @observable config: any = null;
    @observable loading: any = false;
    @observable process: any = false;
    @observable testingAccount: any = null;
    @observable testingOption: any = null;
    @observable student: any = null;
    @observable data: any[] = [];

    @observable isGEPTesting: boolean = false;
    @observable isIEPTesting: boolean = false;
    @observable isTestingChinese: boolean = false;
    @observable isKGETesting: boolean = false;
    @observable placementTest: any = null;

    @observable countdown: any = null;

    constructor(
        private ds: DataService,
        private presence: PresenceService,
        private snackBar: MatSnackBar,
    ) { }

    async mapTestData(
        docRef: AngularFirestoreCollection,
        files: any[],
        result: (files: any[]) => void,
    ) {
        // const allAsyncResults: any[] = []
        const allAsyncResults = await Promise.all(files.map(async (doc, i) => {
            const data: any[] = doc?.questions && doc?.questions?.length > 0 ? doc.questions : pushToArray(await docRef.doc(doc.key).collection("questions").get().toPromise())
            const randomType = doc.type.key === 11 // word order
            return {
                ...doc,
                questions: shuffle(data || []).map((m, i) => ({
                    ...m,
                    correct_order_answer: randomType ? m.answers : [],
                    answers: (randomType ? shuffle(m.answers || []).map((m, i) => ({ ...m, shuffleOrder: i })) : m.answers) || null,
                    questions: shuffle(m.questions || []).map((m, i) => ({ ...m, shuffleOrder: i })),
                    shuffleOrder: i
                }))
            }
        }))
        result(allAsyncResults)
    }



    @action
    async fetchTestingQuestions(uid, callback) {
        this.loading = true;
        const config = await this.ds.testingOption().get().toPromise();
        const student = await this.ds.studentRef().doc(uid).get().toPromise();
        this.student = pushToObject(student);
        const account = await this.ds.studentTestingRef(uid).get().toPromise();
        this.testingAccount = account.empty ? null : pushToArray(account)[0]
        this.testingOption = pushToObject(config);

        const { KGE_TYPE } = this.testingOption;
        const { subject_type, programLevel } = this.testingAccount;
        this.isGEPTesting = this.testingOption.test_GEP.key == this.testingAccount.program.key
        this.isIEPTesting = this.testingOption.test_IEP.key == this.testingAccount.program.key
        this.isTestingChinese = this.testingOption.test_CHINESE.key == this.testingAccount.program.key
        this.isKGETesting = this.testingOption.test_KGE.key == this.testingAccount.program.key

        if (this.isKGETesting) {
            const docs = await this.ds.testingKGEProgramRef(KGE_TYPE, subject_type, programLevel).get().toPromise();
            await this.mapTestData(this.ds.testingKGERef(), pushToArray(docs), async results => {
                this.data = orderBy(results, "created_at");
                callback(this.data);
                this.loading = false;
            })
        } else if (this.isIEPTesting) {
            const gradeKey = subject_type.key === 3 ? programLevel.gradeKey : this.testingOption.IEP_TESTING_KEY[`${programLevel.gradeKey}`];

            const iepDocs = await this.ds.testingIEPRef(subject_type, gradeKey).get().toPromise();
            await this.mapTestData(this.ds.testingKGERef(), pushToArray(iepDocs), results => {
                this.data = orderBy(results, "created_at");
            })
            if (iepDocs.size == 0 && this.isIEPTesting) {
                this.ds.testingAccountRef(this.testingAccount.key)
                    .update({ subject_type: ARRAY_SUBJECT_DATA[2] })
                    .then(async () => {
                        this.testingAccount = {
                            ...this.testingAccount,
                            subject_type: ARRAY_SUBJECT_DATA[2]
                        };
                        this.loading = false;
                        callback(this.data);
                    });
            } else {
                this.loading = false;
                callback(this.data);
            }
        } else if (this.isTestingChinese) {
            this.data = [];
            this.loading = false;
            callback(this.data);
        }
        else if (ageFromDateOfBirthday(timestampToDate(this.testingAccount.dob)) < 6 && this.isGEPTesting) {
            const docs = await this.ds.testingKindergartenRef().get().toPromise();
            await this.mapTestData(this.ds.testingKindergartenRef(), pushToArray(docs), results => {
                this.data = orderBy(results, "created_at");
                this.loading = false;
                callback(this.data);
            })
        } else {
            const gepDocs = await this.ds.testingGepRef().get().toPromise();
            await this.mapTestData(this.ds.testingGepRef(), pushToArray(gepDocs), async results => {
                this.data = orderBy(results, "created_at");
                this.loading = false;
                callback(this.data);
            })
        }
    }

    @action
    startTest(callback?: any) {
        this.loading = true;
        const batch = this.ds.batch();
        const { key, student, subject_type } = this.testingAccount;
        if (this.isKGETesting || this.isIEPTesting) {
            batch.update(this.ds.testingAccountFireRef(key), { isTakeTest: true, subject_type: subject_type ? subject_type : SUBJECT_DATA.khmer })
        } else {
            batch.update(this.ds.testingAccountFireRef(key), { isTakeTest: true })
        }
        batch.update(this.ds.studentFireRef().doc(student.key), {
            placementTest: {
                startDate: this.ds.serverTimestamp(),
                testingKey: key,
                isKGE: this.isKGETesting,
                subject_type: this.isKGETesting || this.isIEPTesting ? !subject_type ? SUBJECT_DATA.khmer : subject_type : null,
            }
        })
        const ref = this.ds.testingAccountFireRef(key)
        this.data.forEach((question: any) => {
            const { type, exam_type } = question;
            if (!exam_type && type.key === 3) {
                batch.set(ref.collection("placementTest").doc(question.key), question, { merge: true })
            }
            else {
                const questions: any[] = question.questions;
                batch.set(ref.collection("placementTest").doc(question.key), {
                    ...question,
                    questions: null,
                }, { merge: true })
                questions.forEach(option => {
                    batch.set(ref.collection("placementTest").doc(question.key).collection("questions").doc(option.key), option)
                }, { merge: true })
            }
        })
        batch.commit().then(() => {
            callback(true)
        }).catch(error => {
            alert(error)
            callback(false)
        }).finally(() => {
            this.loading = false;
        });
    }

    @action
    async fetchTestingProfile(uid) {
        this.loading = true;
        const config = await this.ds.testingOption().get().toPromise();
        const student = await this.ds.studentRef().doc(uid).get().toPromise();
        this.student = pushToObject(student);
        const account = await this.ds.studentTestingRef(uid).get().toPromise();
        this.testingAccount = account.empty ? null : pushToArray(account)[0];
        this.testingOption = pushToObject(config);
        this.loading = false;
    }

    @action
    async checkExistSubject(subjectId: any, testingKey) {
        const docs = await this.ds.testingAccountRef(testingKey)
            .collection("placementTest", ref => ref.where("subject.key", "==", ConvertService.toNumber(subjectId)))
            .get().toPromise()
        return docs.empty ? false : true
    }

    @action
    async fetchStudentPlacementTest(uid: any, callback: any) {
        this.loading = true;
        const testingKey = this.testingAccount.key;

        const student = await this.ds.studentRef().doc(uid).get().toPromise();
        this.student = pushToObject(student);

        const { placementTest } = this.student;
        this.placementTest = placementTest || null;

        this.isKGETesting = this.testingOption.test_KGE.key === this.testingAccount.program.key;
        this.isIEPTesting = this.testingOption.test_IEP.key == this.testingAccount.program.key;
        const testingAccount: any = this.testingAccount;

        const accountRef = (this.isKGETesting || this.isIEPTesting) ?
            this.ds.testingAccountRef(testingKey).collection("placementTest", ref => ref.where("subject.key", "==", testingAccount.subject_type ? testingAccount.subject_type.key : SUBJECT_DATA.khmer)) :
            this.ds.testingAccountRef(testingKey).collection("placementTest");
        const placementTestDuration = (this.isKGETesting || this.isIEPTesting) ? this.testingOption.kgeTestDuration : this.testingOption.placementTestDuration;
        const docs = pushToArray(await accountRef.get().toPromise());
        this.mapStudentTestData(testingKey, docs, async items => {
            this.data = orderBy(items.map(m => ({ ...m, topicFile: Array.isArray(m.topicFile) && m.topicFile.length === 0 ? null : m.topicFile })), "created_at")
            if (placementTest) {
                const { startDate } = placementTest;
                const serverTime = new Date((await getServerTime(uid)).timestamp)
                const timeLeft = TimeLeft(startDate?.toDate() || serverTime, serverTime)
                const countdown = (placementTestDuration * 60) - timeLeft
                this.countdown = countdown;
                if (countdown <= 0 && ((!this.isKGETesting && this.testingAccount.subject_type && this.testingAccount.subject_type.key != 1) && (!this.isIEPTesting && this.testingAccount.subject_type && this.testingAccount.subject_type.key != 1))) {
                    this.ds.studentRef().doc(uid).update({
                        placementTest: null
                    }).then(() => {
                        this.loading = false;
                        callback(this.data)
                    })
                    return;
                }
                this.loading = false;
                callback(this.data)
            }
            else {
                this.ds.studentRef().doc(uid).update({
                    placementTest: null
                }).then(() => {
                    this.loading = false;
                    callback(this.data)
                })
            }
        })
    }

    async mapStudentTestData(
        testingKey: any,
        files: any[],
        result: (files: any[]) => void,
    ) {
        const allAsyncResults: any[] = []
        for (const doc of files) {
            const data: any[] = pushToArray(await this.ds.testingAccountRef(testingKey).collection("placementTest").doc(doc.key).collection("questions").get().toPromise());
            const questionsData = doc.questions && doc.questions.length > 0 ? doc.questions : data.map(m => ({ ...m, questions: shuffle(m.questions || []) }))
            let test = {
                ...doc,
                questions: orderBy(questionsData, 'shuffleOrder')
            };
            allAsyncResults.push(test)
        }
        result(allAsyncResults)
    }

    @action
    async studentSelectedAnswer(testingKey: any, sectionKey: any, questionKey: any, answer: any, typeKey?: any, selectedAnswerFieldKey?: string) {
        let storeAnswer: any = null;
        this.process = true;
        const newData = this.data.map(m => {
            if (m.key === sectionKey) {
                const questions: any[] = m.questions;
                return {
                    ...m,
                    questions: questions.map(q => {
                        if (q.key === questionKey) {
                            const { exam_type, type } = m || {}
                            if (exam_type && (type?.key === 1 || type?.key === 11)) {
                                const answers: any[] = q?.answers
                                if (Array.isArray(answers)) {
                                    const numAnswer = answers.filter((m) => m.isCorrect)
                                    if (numAnswer.length > 1) {
                                        const arrayStudentAnswer: any[] = q.studentAnswer || [];
                                        const atLastOneAnswer = arrayStudentAnswer.length === 1
                                        const answered = arrayStudentAnswer.find(m => m.key === answer.key)
                                        const newAnswer = answered ? atLastOneAnswer ? arrayStudentAnswer : arrayStudentAnswer.filter(m => m.key != answer.key) : updateObjArray(q.studentAnswer || [], answer)
                                        storeAnswer = newAnswer
                                        return { ...q, studentAnswer: newAnswer }
                                    }
                                    if (selectedAnswerFieldKey) {
                                        const addFieldKeyData = {
                                            ...answer,
                                            answerFieldKey: selectedAnswerFieldKey,
                                        }
                                        const newAnswer = updateObjArray(q?.studentAnswer || [], addFieldKeyData)
                                        storeAnswer = newAnswer
                                        return { ...q, studentAnswer: newAnswer }
                                    }
                                    storeAnswer = [answer]
                                    return { ...q, studentAnswer: [answer] }
                                }
                            }
                            else {
                                storeAnswer = answer
                                return { ...q, studentAnswer: answer }
                            }
                        }
                        return q;
                    })
                }
            }
            return m;
        })
        this.ds.testingAccountRef(testingKey)
            .collection("placementTest").doc(sectionKey)
            .collection("questions").doc(questionKey).update({
                answer_date: this.ds.serverTimestamp(),
                studentAnswer: storeAnswer,
            }).then(() => {
                this.data = newData;
                this.snackBar.open('Your answer has been saved.', "Done", { duration: 2000 })
            }).finally(() => {
                this.process = false;
            })
    }

    @action
    submitTesting(uid: string, callback: (item: any, next: boolean, testing: any) => void) {
        this.process = true;
        if (this.isKGETesting && this.testingAccount.subject_type.key === 1) {
            this.ds.testingAccountRef(this.testingAccount.key)
                .update({ subject_type: SUBJECT_DATA.math, isTakeTest: false })
                .then(() => {
                    this.testingAccount = {
                        ...this.testingAccount,
                        subject_type: SUBJECT_DATA.math
                    }
                    callback(this.data, true, this.testingAccount)
                }).finally(() => {
                    this.process = false;
                });
        } else if (this.isIEPTesting && this.testingAccount.subject_type.key < 3) {
            const subjectKey = this.testingAccount.subject_type.key - 1
            this.ds.testingAccountRef(this.testingAccount.key)
                .update({ subject_type: ARRAY_SUBJECT_DATA[subjectKey + 1], isTakeTest: false })
                .then(() => {
                    this.testingAccount = {
                        ...this.testingAccount,
                        subject_type: ARRAY_SUBJECT_DATA[subjectKey + 1]
                    };
                    callback(this.data, true, this.testingAccount)
                }).finally(() => {
                    this.process = false;
                });
        } else {
            const batch = this.ds.batch();
            const studentRef = this.ds.studentFireRef().doc(uid)
            const testingRef = this.ds.testingAccountFireRef(this.testingAccount.key)
            batch.update(studentRef, {
                placementTest: null,
            })
            batch.update(testingRef, {
                used: true,
            })
            batch.commit().then(async () => {
                callback(this.data, false, null)
            }).finally(() => {
                this.process = false;
            });
        }
    }
}
