import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { Router } from '@angular/router';
import { Environment } from 'src/app/core/stores/environment';
import { TestingStore } from 'src/app/core/stores/testing.store';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmSuccessComponent } from 'src/app/shared/components/confirm-success/confirm-success.component';
import { DataService } from 'src/app/core/services/data.service';

interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'dashboard-nav',
  templateUrl: './dashboard-nav.component.html',
  styleUrls: ['./dashboard-nav.component.scss']
})
export class DashboardNavComponent implements OnInit {
  @Input() user: any;
  @Input() student: any;

  timeLeft: any;
  constructor(
    public router: Router,
    public store: Environment,
    public testing: TestingStore,
    public dialog: MatDialog,
    private ds: DataService,
    public auth: AuthStore,) { }

  ngOnInit() {

  }

  onHandleEvent(event) {
    if (!event) return;
    const { action } = event;
    if (action && `${action}`.toLocaleLowerCase() === 'done') {
      const { student } = this.testing;
      this.testing.submitTesting(student.key, (data, next, testing) => {
        if (!next) {
          // this.auth.signOut();
          this.router.navigate([`/submited`])
          return;
        }
        this.router.navigate([`/`], { queryParams: { next: testing.subject_type.key } })
      })
    }
  }

  _signOut() {
    // this.auth.signOut();
  }

  _onSubmit() {
    const { student, testingAccount, data } = this.testing;
    const allQuestions: any[] = []
    const writingQuestions: any[] = []
    data.forEach((test) => test.type.key === 3 ? writingQuestions.push(test) : test.questions.forEach((m: any) => allQuestions.push(m)))
    const remainingQuestions = allQuestions.filter((m: any) => !m.studentAnswer).length + writingQuestions.length
    let dialogRef = this.dialog.open(ConfirmSuccessComponent, {
      data: { title: `Submit Test`, memo: 'Once you submit this test, the system will end the exam, and you cannot log in to make any changes. So you must review your work and make sure you are ready before submitting the test.', name: `${testingAccount.program.name} ${remainingQuestions > 0 ? `${remainingQuestions} Question(s) Incomplete` : ''}` },
      width: '35vw',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.testing.submitTesting(student.key, (data, next, testing) => {
          if (!next) {
            // this.auth.signOut();
            this.router.navigate([`/submited`])
            return;
          }
          this.router.navigate([`/`], { queryParams: { next: testing.subject_type.key } })
        })
      }
    });
  }

  toggleSidebar() {
    let body = document.getElementsByClassName("page-wrapper")[0];
    body.classList.toggle("toggled-switch-menu");
  }

}
