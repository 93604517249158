<table class="table-wrapper listing-table">
  <thead class="table-header">
    <tr class="table-row">
      <th class="table-col khmer">{{'ក'}}</th>
      <th class="table-col khmer">{{'ខ'}}</th>
      <th class="table-col khmer">{{'គ'}}</th>
    </tr>
  </thead>
  <tbody class="table-body">
    <tr *ngFor="let item of question?.questions; let i=index;">
      <td class="table-col ">
        {{i+1}}. <span *ngIf="item?.name && !item?.textQuestions" [innerHTML]="item?.name"></span>
        <app-display-math-type *ngIf="item?.textQuestions" [textArray]="item?.textQuestions">
        </app-display-math-type>
      </td>
      <td class="table-col ">
        <span *ngIf="item?.name && !item?.textQuestions" [innerHTML]="PATH_B[i]?.name"></span>
        <app-display-math-type *ngIf="item?.textQuestions" [textArray]="PATH_A[i]?.answerOptions">
        </app-display-math-type>
      </td>
      <td class="table-col " style="width: 300px;">
        <div class="ui form">
          <div class="rows">
            <strong>{{i+1}}.</strong>
            <div class="field" style="flex: 1;">
              <mat-select [disabled]="store?.process || store?.loading" class="fill-form-input"
                [value]="item?.studentAnswer" [compareWith]="ds?.compareObject"
                (selectionChange)="onChanged($event,item)">
                <mat-option *ngFor="let row of PATH_A;let j=index" [value]="row">
                  <strong>{{j+1}}. </strong>
                  <span *ngIf="item?.name && !item?.textQuestions" [innerHTML]="row?.name"></span>
                  <app-display-math-type *ngIf="item?.textQuestions" [textArray]="row?.answerOptions">
                  </app-display-math-type>
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
