import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { Register } from 'src/app/core/stores/register.store';
import { TestingStore } from 'src/app/core/stores/testing.store';

@Component({
  selector: 'app-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss']
})
export class RegisterSuccessComponent implements OnInit {

  profileTestingAccount: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private register: Register,
    public auth: AuthStore,
    public store: TestingStore,
  ) { }

  async ngOnInit() {
    const studentID = this.router.url;
    const spiteArray = studentID.split('/');
    const id = spiteArray[2];
    await this.register.studentProfileSignUp(id, callback => {
      this.profileTestingAccount = callback;
    });
  }

  process: boolean = false;
  startExam() {
    this.process = true;
    this.auth.signIn(this.profileTestingAccount.serial_id, "016555392", async (user, success) => {
      if (success) {
        const { uid } = user;
        await this.store.fetchTestingProfile(uid);
        const { student, testingAccount } = this.store;
        if (!testingAccount) {
          alert('This serial number is invalid. Please verify that your serial number has an active placement test.')
          this.auth.signOut();
          this.process = false;
          return;
        }
        const { placementTest } = student;
        if (placementTest) {
          this.process = false;
          this.router.navigate(['/']);
          return;
        }
        this.store.fetchTestingQuestions(uid, res => {
          if (res && res.length === 0) {
            alert('No Placement Test available for this student. Please make sure the student is enrolled in a program with an active placement test.')
            this.auth.signOut();
            this.process = false;
            return;
          }
          this.store.startTest(res => {
            this.process = false;
            this.process = false;
            if (res) {
              this.router.navigate(['/']);
            }
            else {
              alert('No Placement Test available for this student. Please make sure the student is enrolled in a program with an active placement test.')
              this.auth.signOut();
              this.process = false;
              return;
            }
          })
        })
      } else {
        this.process = false;
      }
    }
    )
  }

}
