import { Component, OnInit, Input } from '@angular/core';
import { toJS } from 'mobx';
import { DataService } from 'src/app/core/services/data.service';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { TestingStore } from 'src/app/core/stores/testing.store';
import { orderBy, shuffleArray } from '../../services/mapping.service';

@Component({
  selector: 'app-audio-exercise',
  templateUrl: './audio-exercise.component.html',
  styleUrls: ['./audio-exercise.component.scss']
})
export class AudioExerciseComponent implements OnInit {
  @Input() question: any;
  @Input() group: any;
  @Input() index: number;

  PATH_B: any[] = [];
  PATH_A: any[] = [];
  DISABLED: boolean = false;
  constructor(public store: TestingStore,
    public ds: DataService,
    public auth: AuthStore) { }

  ngOnInit() {
    this.PATH_B = orderBy(this.question.questions, 'shuffleOrder')
    this.PATH_A = orderBy(this.question.questions, "name");
  }

  onChanged(event, question) {
    const { value } = event;
    const { testingAccount } = this.store;
    this.store.studentSelectedAnswer(testingAccount.key, this.group.key, question.key, value, this.group.type.key)
  }

  onPlay(path) {
    this.DISABLED = true;
    let audio = new Audio();
    audio.src = path;
    audio.load();
    audio.play().then(() => {
    }).finally(() => {
      this.DISABLED = false;
    });
  }

}
