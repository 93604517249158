<div class="containerRegister">
    <div class="sovannaphumLogo">
        <div class="logo">
            <img src="../../../assets/images/Logo-SPS.png" alt="">
        </div>
    </div>
    <div class="bodyRegisterSuccess">
        <div style="padding: 30px; box-sizing: border-box;" class="width49percent box-shadow border-radius-20px">
            <div class="contain" style="margin-top: 10px;">
                <div class="left">Serial ID :</div>
                <div class="right">{{ profileTestingAccount?.serial_id}}</div>
            </div>
            <div class="contain" style="margin-top: 10px;">
                <div class="left">Full Name :</div>
                <div class="right">{{ profileTestingAccount?.full_name }}</div>
            </div>
            <div class="contain" style="margin-top: 10px;">
                <div class="left">Gender :</div>
                <div class="right">{{ profileTestingAccount?.gender?.text }}</div>
            </div>
            <div class="contain" style="margin-top: 10px;">
                <div class="left">Date Of Birth :</div>
                <div class="right">{{ profileTestingAccount?.dob.toDate() | date }}</div>
            </div>
            <div class="contain" style="margin-top: 10px;">
                <div class="left">Phone Number :</div>
                <div class="right">{{ profileTestingAccount?.mobile_phone}}</div>
            </div>
            <div class="contain" style="margin-top: 10px;">
                <div class="left">School :</div>
                <div class="right">{{ profileTestingAccount?.school?.name}}</div>
            </div>
            <div class="contain" style="margin-top: 10px;">
                <div class="left">Campus :</div>
                <div class="right">{{ profileTestingAccount?.campus?.name}}</div>
            </div>
            <div class="contain" style="margin-top: 10px;">
                <div class="left">Program :</div>
                <div class="right">{{ profileTestingAccount?.program?.name}}</div>
            </div>
            <div class="contain" style="margin-top: 10px;" *ngIf="profileTestingAccount?.programLevel?.name">
                <div class="left">Level :</div>
                <div class="right">{{ profileTestingAccount?.programLevel?.name}}</div>
            </div>
            <div class="contain" style="margin-top: 10px;">
                <div class="left">Shift :</div>
                <div class="right">{{ profileTestingAccount?.shift?.name}}</div>
            </div>
        </div>
        <div style="padding: 30px; box-sizing: border-box;"
            class="heigh100percent width49percent box-shadow border-radius-20px margin-left-2percent">
            <img src="../../../assets/images/detail.jpg" alt="">
        </div>
    </div>
    <div class="containerButtonStartExam">
        <div class="startExam" (click)="startExam()">

            <span *ngIf="process==false">
                START EXAM
            </span>
            <span *ngIf="process">
                Process...
            </span>
            <span class="material-icons-outlined" style="margin-left: 20px;">
                east
            </span>
        </div>
    </div>


</div>
