import { Component, Input, OnInit } from '@angular/core';
import { TestingStore } from 'src/app/core/stores/testing.store';

@Component({
  selector: 'app-true-false-exercise',
  templateUrl: './true-false-exercise.component.html',
  styleUrls: ['./true-false-exercise.component.scss']
})
export class TrueFalseExerciseComponent implements OnInit {
  @Input() question: any;
  @Input() group: any;

  TRUE_FALSE = [
    { key: 1, name: "ត្រូវ", en_name: true, text: 'True (ត្រូវ)' },
    { key: 2, name: "ខុស", en_name: false, text: 'False (ខុស)' }
  ]
  constructor(public store: TestingStore) { }

  ngOnInit(): void {
  }

  onChanged(event, question) {
    const { value } = event;
    const { testingAccount } = this.store;
    this.store.studentSelectedAnswer(testingAccount.key, this.group.key, question.key, value,this.question.type.key)
  }

}
