<div class="doc-mcq" [ngClass]="question?.studentAnswer?'active':''">
    <h5>
        <strong *ngIf="totalCheck>1">({{totalCheck}} answers)</strong>
        <span>{{index}}.&nbsp;</span>
        <span *ngIf="!question?.textQuestions || question?.textQuestions?.length===0">{{question?.name}}</span>
        <app-display-math-type *ngIf="question?.textQuestions && question?.textQuestions?.length>0" [textArray]="question?.textQuestions">
        </app-display-math-type>
    </h5>
    <img style="width: 150px;" class="doc-img" *ngFor="let im of question?.files;" [src]="im?.downloadUrl"/>
    <ng-container *ngIf="!isMulti">
    <mat-radio-group *ngIf="question?.questions" (change)="onChanged($event)" class="mcq-options">
        <mat-radio-button [disabled]="store?.process || store?.loading" [checked]="item?.key|findAnswerKey:question?.studentAnswer" [value]="item" class="mq-item"
            *ngFor="let item of question?.questions">
            <span *ngIf="item?.name" [innerHTML]="item?.name"></span>
            <img *ngIf="item?.file?.downloadUrl" [src]="item?.file?.downloadUrl" />
            <app-display-math-type *ngIf="item?.textQuestions" [textArray]="item?.textQuestions">
            </app-display-math-type>
        </mat-radio-button>
    </mat-radio-group>

    <mat-radio-group *ngIf="question?.answers" (change)="onChanged($event)" class="mcq-options">
        <mat-radio-button [disabled]="store?.process || store?.loading" [checked]="item?.key|findAnswerKey:question?.studentAnswer" [value]="item" class="mq-item"
            *ngFor="let item of question?.answers;let j=index">
            <span *ngIf="item?.name && !item?.textOptions" [innerHTML]="item?.name"></span>
            <app-display-math-type *ngIf="item?.textOptions" [textArray]="item?.textOptions">
            </app-display-math-type>
            <img *ngIf="item?.file?.downloadUrl" [src]="item?.file?.downloadUrl" />
        </mat-radio-button>
    </mat-radio-group>
</ng-container>

    <mat-selection-list *ngIf="isMulti" (selectionChange)="onSelectionChange($event)" class="mcq-options">
        <mat-list-option checkboxPosition="before" 
        [disabled]="store?.process || store?.loading" 
        [selected]="item?.key|findAnswerKey:question?.studentAnswer" [value]="item" class="mq-item" *ngFor="let item of question?.answers">
          <span *ngIf="item?.name && !item?.textOptions" [innerHTML]="item?.name"></span>
            <app-display-math-type *ngIf="item?.textOptions" [textArray]="item?.textOptions">
            </app-display-math-type>
            <img *ngIf="item?.file?.downloadUrl" [src]="item?.file?.downloadUrl" />
        </mat-list-option>
      </mat-selection-list>

</div>