import { AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as moment from 'moment'
import { daysOfWeek } from 'src/app/core/dummy/stauts';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class ConvertService {

  constructor() { }
  static numberOnly(sender: any) {
    let numb = sender.match(/\d/g);
    numb = numb.join("");
    return this.toNumber(numb);
  }
  static toNumber(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(value);
  }
  static toYear(date: Date) {
    return moment(date).format('YYYY');
  }
  static getDaySchedule(days) {
    var daysInput = Object.keys(days).map(function (key) {
      return { name: key, value: days[key] };
    });

    daysInput = daysInput.filter(m => m.value === true)
    daysInput.sort((a: any, b: any) => {
      var day1 = a.name
      var day2 = b.name
      if (daysOfWeek[day1] < daysOfWeek[day2]) {
        return -1;
      } else if (daysOfWeek[day1] > daysOfWeek[day2]) {
        return 1;
      } else {
        return 0;
      }
    });
    let result = '';
    daysInput.forEach(item => {
      if (item.name === 'monday')
        result = result + 'Monday / '
      else if (item.name.toLowerCase() === 'tuesday')
        result = result + 'Tuesday / '
      else if (item.name.toLowerCase() === 'friday')
        result = result + 'Friday / '
      else if (item.name.toLowerCase() === 'saturday')
        result = result + 'Saturday / '
      else if (item.name.toLowerCase() === 'sunday')
        result = result + 'Sunday / '
      else if (item.name.toLowerCase() === 'thursday')
        result = result + 'Thursday / '
      else
        result = result + 'Wednesday / '
    })
    if (result.length > 3)
      result = result.substring(0, result.length - 3)
    return result;
  }
  static toFloatFixed(value, fix) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(value.toFixed(fix));
  }

  static toCapitalize(value) {
    let string = null;
    if (value) string = value.toUpperCase().toString().trim();
    return string;
  }

  static getSessionDayTrue(session) {
    let result = [];
    if (session && session.days) {
      let daysInput = Object.keys(session.days).map(function (key) {
        return { name: key, value: session.days[key] };
      });

      result = daysInput.filter(m => m.value === true);
    }
    return result;
  }

  static getNumber(value: any, getval: any): number {
    if (value === NaN || value === null || value === undefined || value === '') {
      return getval;
    }
    return Number(value);
  }

  static toNull(value) {
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value;
  }

  static toNullInputValue(input: AbstractControl) {
    if (input === undefined || undefined === null) return null;
    const value = input.value;
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value;
  }

  static toNullInputKey(input: AbstractControl) {
    if (input === undefined || undefined === null) return null;
    const value = input.value;
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value.key;
  }

  static yearSuffix() {
    return moment().format('YY').toString();
  }
  static addWeek(interval: number) {
    return moment().add(interval, 'week').toDate()
  }

  static addMonth(interval: number) {
    return moment().add(interval, 'month').toDate()
  }

  static pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
  }

  static toDateKey(date: Date) {
    return Number(moment(date).format('YYYYMMDD'))
  }

  static dateKey() {
    return Number(moment().format('YYYYMMDD'))
  }
  static toFloatFixed2(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(value.toFixed(2));
  }
  static hourToNumber(time) {
    return Number(moment(time, 'hh:mm a').format('HH').toString() + '.' + moment(time, 'hh:mm a').format('mm').toString());
  }

  static toHour(time) {
    return moment(time, 'hh:mm a').format('hh:mm a')
  }

  static generate_testing_invoiceNo(config) {
    var number = moment().format('YYMMDD').toString() + (config.invoice_shufit + 1).toString();
    return Number(number);
  }

  static generate_puc_id(config) {
    var number = moment().format('YY').toString() + (config.puc_id + 1).toString();
    return number;
  }

}

export function toUpperCase(value: string) {
  if (value === "" || value === undefined) {
    return null;
  }
  return value.toUpperCase();
}

export function sum(data: Array<any>, field: string) {
  if (!data) return 0;
  return data.reduce((a, b) => a + toNumber(b[field]), 0)
}

export function toNumber(value) {
  if (value === null || value === "" || value === undefined) {
    return 0;
  }
  if (Number(value) === NaN) return 0;
  return Number(value);
}

export function dateKeyToDate(dateKey: string | number) {
  return Number(moment(dateKey, 'YYYYMMDD').toDate())
}
export function fromDateKeyToDate(dateKey: string | number) {
  return moment(dateKey, 'YYYYMMDD').toDate()
}

export function createUniqueKey(firstName: string, lastName: string, birthDateKey: number, gender: string) {
  return ((firstName.trim() + lastName.trim() + birthDateKey + gender).substring(0).toLowerCase())
}

export function generate_serialID(serial_number) {
  const serialID = serial_number + 1;
  const serial_id = 'S' + serialID.toString();
  return serial_id;
}

export function toDateCalendar(date: any) {
  return moment(date, 'YYYY-MM-DD').toDate();
}

export function toDateKey(date: Date) {
  return Number(moment(date).format('YYYYMMDD'))
}

export function pageKey(date?: Date) {
  return Number(moment(date || new Date()).format('YYYYMMDDHHmmss'));
}

export function incrementRef(amount: number) {
  return (firebase.firestore.FieldValue.increment(amount))
}
