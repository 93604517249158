import { Component, Input, OnInit } from '@angular/core';
import { toJS } from 'mobx';
import { DataService } from 'src/app/core/services/data.service';
import { TestingStore } from 'src/app/core/stores/testing.store';
import { orderBy, shuffleArray } from '../../services/mapping.service';

@Component({
  selector: 'app-fill-in-the-blanks',
  templateUrl: './fill-in-the-blanks.component.html',
  styleUrls: ['./fill-in-the-blanks.component.scss']
})
export class FillInTheBlanksComponent implements OnInit {
  @Input() question: any;
  @Input() group: any;
  @Input() index: number;

  wordBanks: any = [];
  constructor(public store: TestingStore,
    public  ds:DataService,) { }

  ngOnInit(): void {
    this.wordBanks = orderBy(this.question.questions.map(m => {
      const { textQuestions } = m;
      return textQuestions.filter(m => m.isFill);
    }), 'shuffleOrder')
  }

  onChanged(event, row) {
    const { value } = event;
    const { testingAccount } = this.store;
    this.store.studentSelectedAnswer(testingAccount.key, this.group.key, row.key, [value], this.question.type.key)
  }

}
