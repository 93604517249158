import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import { tap, map, switchMap, first } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PresenceService {

    constructor(private afAuth: AngularFireAuth,
        private db: AngularFireDatabase) {
        this.updateOnUser().subscribe();
        this.updateOnDisconnect().subscribe();
        this.updateOnAway();
    }

    getUser() {
        return this.afAuth.authState.pipe(first()).toPromise();
    }

    getPresence(uid: string) {
        return this.db.object(`status/${uid}`).valueChanges();
    }

    getPresenceValue(uid: string) {
        return this.db.object(`status/${uid}`).valueChanges();
    }

    getUIDStatusObject(uid: string) {
        return this.db.object(`status/${uid}`)
    }

    async setPresence(status: string) {
        const user = await this.getUser();
        if (user) {
            return this.db.object(`status/${user.uid}`).update({ status, timestamp: this.timestamp });
        }
    }


    get timestamp() {
        return firebase.database.ServerValue.TIMESTAMP;
    }

    updateOnUser() {
        const connection = this.db.object('.info/connected').valueChanges().pipe(
            map(connected => connected ? 'online' : 'offline')
        );
        return this.afAuth.authState.pipe(
            switchMap(user => user ? connection : of('offline')),
            tap(status => this.setPresence(status))
        );
    }

    updateOnDisconnect() {
        return this.afAuth.authState.pipe(
            tap(user => {
                if (user) {
                    this.db.object(`status/${user.uid}`).query.ref.onDisconnect()
                        .update({
                            status: 'offline',
                            timestamp: this.timestamp
                        });
                }
            })
        );
    }

    updateOnAway() {
        document.onvisibilitychange = (e) => {
            if (document.visibilityState === 'hidden') {
                this.setPresence('away');
            } else {
                this.setPresence('online');
            }
        };
    }

}

export function timestamp() {
    return firebase.database.ServerValue.TIMESTAMP;
}
export async function getServerTime(uid: string) {
    return firebase.database().ref(`status/${uid}`).update({ status: 'online', timestamp: timestamp() }).then(async () => {
        const val = await firebase.database().ref(`status/${uid}`).once('value');
        const time: { status: string; timestamp: number } = val.val()
        return time
    }).catch(e => {
        return { status: 'offline', timestamp: +Date.now() }
    })

}