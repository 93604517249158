export const SCHOOL = {
  name: "Sovannaphumi School",
  name_kh: "សាលារៀនសុវណ្ណភូមិ",
}


export const FILTER_OPTIONS = [
  { key: "code", text: "ID" },
  { key: "full_name", text: "Full name" },
  { key: "first_name", text: "First name" },
  { key: "last_name", text: "Last name" },
  { key: "mobile_phone", text: "Phone number" }
];

export const APP_NAME = {
  'human-resource': { key: 'human-resource', router: 'human-resource', name: 'Human Resource' }
}

export const APP_NAME_LIST = [
  { key: 'human-resource', router: 'human-resource', name: 'Human Resource' },
  { key: 'inventory', router: 'inventory', name: 'Inventory' },
]

export const PRODUCT_TYPE_OPTIONS = [
  { key: 1, router: 'inventory', name: 'Inventory' },
  { key: 0, router: 'non-inventory', name: 'Non-Inventory' },
  { key: 3, router: 'bundle', name: 'bundle' },
]

export const PRODUCT_TYPE_OPTION_OBJ = {
  'inventory': { key: 1, router: 'inventory', name: 'Inventory' },
  'non-inventory': { key: 0, router: 'non-inventory', name: 'Non-Inventory' },
  'bundle': { key: 3, router: 'bundle', name: 'bundle' },
}

export const APP_FUNCTIONS = {
  'inventory': {
    key: 'inventory',

    routeItems: [
      {
        key: 'purchase',
        name: 'Purchases',
        expansions: 'three',
        expansionIndex: 3,
        icon: '',
        remark: 'Bill, Pay Bills, Debit Note, Supplier',
        data: [
          { key: 'bills', name: 'Bills', icon: 'shopping_basket' },
          { key: 'pay-bills', name: 'Pay Bills', icon: 'description' },
          // { key: 'debit-note', name: 'Debit Note', icon: 'payment' },
          { key: 'suppliers', name: 'Suppliers', icon: 'supervisor_account' },
        ]
      },
      {
        key: 'inventory',
        name: 'Inventory',
        expansions: 'two',
        expansionIndex: 2,
        icon: '',
        remark: 'Inventory Adjustment, Inventory Transfer, Inventory Center',
        data: [
          { key: 'inventory-center', name: 'Inventory Center', icon: 'dns' },
          { key: 'inventory-adjustment', name: 'Inventory Adjustments', icon: 'how_to_vote' },
          { key: 'inventory-transfer', name: 'Inventory Transfer', icon: 'flight_takeoff' },
        ]
      },
      {
        key: 'products',
        name: 'Products',
        expansions: 'one',
        expansionIndex: 1,
        icon: '',
        remark: 'Products, Category, Unit Measurements, Payment Terms',
        data: [
          { key: 'products', name: 'Products', icon: 'playlist_add' },
          { key: 'category', name: 'Category', icon: 'category' },
          { key: 'unit-measurements', name: 'Unit Measurements', icon: 'transform' },
          { key: 'payment-term', name: 'Payment Terms', icon: 'schedule' },

        ]
      },
      
    ]
  },
  'human-resource': {
    key: 'human-resource',
    routeItems: [
      {
        key: 'time-and-attendance',
        name: 'Time and Attendance',
        expansions: 'one',
        expansionIndex: 1,
        icon: '',
        remark: 'Department, Holiday, Position, Country/Nationality',

        data: [
          { key: 'department', name: 'Department', icon: 'flag' },
          { key: 'holiday', name: 'Holiday', icon: 'flag' },
          { key: 'position', name: 'Position', icon: 'flag' },
          { key: 'country-nationality', name: 'Country/Nationality', icon: 'flag' },
        ]
      },
      // {
      //   key: 'grow',
      //   name: 'Grow',
      //   expansions: 'two',
      //   expansionIndex: 2,
      //   icon: '',
      //   remark: 'Department, Holiday, Position, Country/Nationality',
      //   data: [
      //     { key: 'department', name: 'Department', icon: 'flag' },
      //     { key: 'holiday', name: 'Holiday', icon: 'flag' },
      //     { key: 'position', name: 'Position', icon: 'flag' },
      //     { key: 'country-nationality', name: 'Country/Nationality', icon: 'flag' },
      //   ]
      // },
      {
        key: 'analytics',
        name: 'Analytics',
        expansions: 'three',
        expansionIndex: 3,
        icon: '',
        remark: 'Department, Holiday, Position, Country/Nationality',
        data: [
          { key: 'department', name: 'Department', icon: 'flag' },
          { key: 'holiday', name: 'Holiday', icon: 'flag' },
          { key: 'position', name: 'Position', icon: 'flag' },
          { key: 'country-nationality', name: 'Country/Nationality', icon: 'flag' },
        ]
      },
      {
        key: 'resources',
        name: 'Resources',
        expansions: 'four',
        expansionIndex: 4,
        icon: '',
        remark: 'Employees, Teaching Assignment, Lecturer, Faculties, Programs, Campus',
        data: [
          { key: 'employees', name: 'Employees', icon: 'supervised_user_circle' },
          { key: 'teaching-assignment', name: 'Teaching Assignment', icon: 'perm_contact_calendar' },
          { key: 'lecturer', name: 'Lecturer', icon: 'account_circle' },
          { key: 'faculties', name: 'Faculties', icon: 'school' },
          { key: 'programs', name: 'Programs', icon: 'category' },
          { key: 'campus', name: 'Campus', icon: 'explore' },
        ]
      },
      {
        key: 'list',
        name: 'List',
        expansions: 'five',
        expansionIndex: 5,
        icon: '',
        remark: 'Department, Holiday, Position, Country/Nationality',
        data: [
          { key: 'department', name: 'Department', icon: 'business' },
          { key: 'holiday', name: 'Holiday', icon: 'event' },
          { key: 'position', name: 'Position', icon: 'assignment_ind' },
          { key: 'country-nationality', name: 'Country/Nationality', icon: 'flag' },
        ]
      },
      {
        key: 'reports',
        name: 'Reports',
        expansions: 'six',
        expansionIndex: 6,
        icon: '',
        remark: 'Department, Holiday, Position, Country/Nationality',
        data: [
          { key: 'department', name: 'Department', icon: 'flag' },
          { key: 'holiday', name: 'Holiday', icon: 'flag' },
          { key: 'position', name: 'Position', icon: 'flag' },
          { key: 'country-nationality', name: 'Country/Nationality', icon: 'flag' },
        ]
      },

    ]
  }
}

export const ARRAY_SUBJECT_DATA = [
  { key: 1, name: 'ភាសាខ្មែរ', enName: 'Khmer' },
  { key: 2, name: 'គណិតវិទ្យា', enName: 'Math' },
  { key:3, name: "ភាសាអង់គ្លេស", enName: "English"}
 ]

 export const SUBJECT_DATA = {
  khmer: { key: 1, name: 'ភាសាខ្មែរ', enName: 'Khmer' },
  math : { key: 2, name: 'គណិតវិទ្យា', enName: 'Math' },
  english : { key:3, name: "ភាសាអង់គ្លេស", enName: "English"}
}