import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';


@Pipe({
  name: 'shuffleArray'
})
export class ShuffleArrayPipe implements PipeTransform {

  transform(data: any[]): unknown {
    return _.shuffle(data);
  }

}
