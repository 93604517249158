import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findArrayAsnwer'
})
export class FindArrayAsnwerPipe implements PipeTransform {

  transform(value: any[], key: any): unknown {
    if (Array.isArray(value)) {
      return value.find(m=>m.answerFieldKey===key)
    }
    return null;
  }

}
