import { Component, Input, OnInit } from '@angular/core';
import { TestingStore } from 'src/app/core/stores/testing.store';

@Component({
  selector: 'app-mcq',
  templateUrl: './mcq.component.html',
  styleUrls: ['./mcq.component.scss']
})
export class McqComponent implements OnInit {
  @Input() question: any;
  @Input() group: any;
  @Input() index: number;

  isMulti: boolean = false;
  totalCheck: number = 0;
  constructor(public store: TestingStore) { }

  ngOnInit(): void {
    const { answers } = this.question;
    if (this.group && this.group.type.key === 2 && this.group.type.name === 'Reading') {
    }
    this.totalCheck = answers && answers.filter(m => m.isCorrect).length
    this.isMulti = this.totalCheck > 1;
  }

  onChanged(event) {
    const { value } = event;
    const { testingAccount } = this.store;
    this.store.studentSelectedAnswer(testingAccount.key, this.group.key, this.question.key, value)
  }

  onSelectionChange(event) {
    const { value } = event.option;
    const { testingAccount } = this.store;
    this.store.studentSelectedAnswer(testingAccount.key, this.group.key, this.question.key, value)
  }

}
