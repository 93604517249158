import { Component, OnInit } from '@angular/core';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { Environment } from 'src/app/core/stores/environment';
import { TestingStore } from 'src/app/core/stores/testing.store';

@Component({
  selector: 'app-overview-layout',
  templateUrl: './overview-layout.component.html',
  styleUrls: ['./overview-layout.component.scss']
})
export class OverviewLayoutComponent implements OnInit {

  constructor(public store: AuthStore
    ,public student:TestingStore
    , public evn: Environment) { }

  ngOnInit() {
  }

}
