<mat-list role="list" class="tf-items">
    <mat-list-item *ngFor="let item of question?.questions;let i=index" role="listitem">
        <div class="rows tf-text">
            <mat-radio-group 
            [disabled]="store?.process || store?.loading"
            (change)="onChanged($event,item)" aria-label="Select an option">
                <mat-radio-button [checked]="row?.key|findAnswerKey:item?.studentAnswer" *ngFor="let row of TRUE_FALSE" class="tf-check" [value]="row">
                    {{row?.text}}
                </mat-radio-button>
            </mat-radio-group>
            <span style="margin-right: 12px;">:</span>
            <app-display-math-type style="flex:1;" [textArray]="item?.textQuestions"></app-display-math-type>
        </div>
    </mat-list-item>
</mat-list>