import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'completedAnswer'
})
export class CompletedAnswerPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if (value) {
      return value.filter(m => m.studentAnswer).length > 0 ? 'active' : null
    }
    return null;
  }

}
