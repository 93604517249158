import { Component, OnInit, Input } from '@angular/core';
import { toJS } from 'mobx';
import { DataService } from 'src/app/core/services/data.service';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { TestingStore } from 'src/app/core/stores/testing.store';
import { orderBy, shuffleArray } from '../../services/mapping.service';
@Component({
  selector: 'app-matching-exercise',
  templateUrl: './matching-exercise.component.html',
  styleUrls: ['./matching-exercise.component.scss']
})
export class MatchingExerciseComponent implements OnInit {
  @Input() question: any;
  @Input() group: any;
  @Input() index: number;

  PATH_B: any[] = [];
  PATH_A: any[] = [];
  constructor(public store: TestingStore,
    public ds: DataService,
    public auth: AuthStore) { }

  ngOnInit() {
// console.log('question', this.question)
    this.PATH_A = shuffleArray(this.question.questions)
    // this.PATH_A = this.question.questions

    this.PATH_B = orderBy(this.question.questions, 'shuffleOrder')
  }

  onChanged(event, question) {
    const { value } = event;
    const { testingAccount } = this.store;
    this.store.studentSelectedAnswer(testingAccount.key, this.group.key, question.key, { key: value.key, }, this.question.type.key)
  }

}
