import { Component, OnInit } from '@angular/core';
import { SCHOOL } from 'src/app/core/dummy/config';

@Component({
  selector: 'app-login-shell',
  templateUrl: './login-shell.component.html',
  styleUrls: ['./login-shell.component.scss']
})
export class LoginShellComponent implements OnInit {

  school_name = SCHOOL.name
  constructor() { }

  ngOnInit() {
  }

}
